import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {getAccount} from "../selectors";
import {Button} from '../containers'
import Validator from "../utils/validator";
import {I18n} from "../utils/i18n";
import {EMAIL_EXISTS, NAME_TO_LONG, UNDEFINED} from "../utils/error";

class AccountFormProfile extends React.Component {

  constructor(props) {
    super(props);
    const {account} = this.props;

    const profile = account.profile || {};

    this.state = {
      error: Object.assign({}, props.error || {}),
      data: {
        name: profile.name || "",
        biography: profile.biography || "",
        email: account.email || '',
      },
      afterClick: false,
      pending: false,
      success: false
    };
  }

  handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    let error = "";
    switch (name) {
      case 'email':
        error = Validator.EmailField(value);
        break;
      default:
    }
    this.setState({
      error: Object.assign(this.state.error, {[name]: error}),
      data: Object.assign(this.state.data, {[name]: value}),
      afterClick: false,
      pending: false,
      success: false
    })
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({afterClick: true});
    const data = this.state.data;
    return Promise.resolve(this.props.submit(data))
      .then(this.handleSuccess, this.handleError);
  };

  handleSuccess = () => {
    this.setState({success: true});
    setTimeout(() => this.setState({success: false}), 2000);
  };

  handleError = (error) => {
    let e = this.state.error;
    switch (error.code) {
      case 400:
        if (error.message === 'Bad name parameter') {
          e.name = NAME_TO_LONG();
        } else {
          e.email = EMAIL_EXISTS();
        }
        break;
      default:
        e.form = UNDEFINED();
    }
    this.setState({error: e, pending: false});
  };

  handleDisabled = () => {
    const profile = this.props.account.profile || {};
    return (this.state.pending
        || ((profile.name === this.state.data.name)
        && (profile.biography === this.state.data.biography)
        && (this.props.account.email === this.state.data.email))
      )
      || !!(() => {
        // Check exists error
        let count = 0;
        Object.keys(this.state.error).forEach((key) => {
          if (!!this.state.error[key]) ++count
        });
        return !!count;
      })();
  };

  render() {
    return (
      <form>

        <div className={`form-group ${!!this.state.error.form ? "has-danger" : ""}`}>
          <h5 className="text-center text-danger">{this.state.error.form}</h5>
        </div>

        <div className="form-group">
          <label htmlFor="emailInput">{I18n.t('key_AccountFormProfile_LabelEmailAddress')}</label>
          <input type='email'
                 id="emailInput"
                 name="email"
                 placeholder={I18n.t('key_AccountFormProfile_PlaceholderEmailAddress')}
                 className={`form-control ${this.state.error.email ? 'is-invalid' : ''}`}
                 value={this.state.data.email}
                 onChange={this.handleChange}
          />
          <div className="invalid-feedback">{this.state.error.email}</div>
        </div>

        <div className="form-group">
          <label htmlFor="nameInput">{I18n.t('key_AccountFormProfile_LabelName')}</label>
          <input type="text"
                 id="nameInput"
                 name="name"
                 placeholder={I18n.t('key_AccountFormProfile_PlaceholderName')}
                 className={`form-control ${this.state.error.name ? 'is-invalid' : ''}`}
                 value={this.state.data.name}
                 onChange={this.handleChange}
          />
          <div className="invalid-feedback">{this.state.error.name}</div>
        </div>

        <div className="form-group">
          <label htmlFor="bioInput">{I18n.t('key_AccountFormProfile_LabelBio')}</label>
          <textarea name="biography"
                    id="bioInput"
                    placeholder={I18n.t('key_AccountFormProfile_PlaceholderBio')}
                    className={`form-control ${this.state.error.biography ? 'is-invalid' : ''}`}
                    value={this.state.data.biography}
                    rows={4}
                    onChange={this.handleChange}
          />
          <div className="invalid-feedback">{this.state.error.biography}</div>
        </div>

        <div className="text-center">
          <Button type='submit'
                  className='btn btn-primary'
                  disabled={this.handleDisabled() || this.state.afterClick}
                  submit={this.handleSubmit}
                  main={I18n.t('key_Update')}
          />
        </div>

      </form>
    )
  }
}

const mapStateToProps = (state) => ({
  account: getAccount(state),
});

AccountFormProfile.propTypes = {
  error: PropTypes.object,
  submit: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(AccountFormProfile);
