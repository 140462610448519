import {requestJSON} from "../utils/request";
import {serialize} from "../utils/helpers";

const api_host = window._env_.API_HOST;

class MediaPlan {

  static Create(data) {
    let uri = [api_host, "mediaplan"].join("/");
    return requestJSON("POST", uri, data, true);
  }

  static Fetch(mediaplan) {
    let uri = [api_host, "mediaplan", mediaplan].join("/");
    return requestJSON("GET", uri, null, true);
  }

  static List(opts) {
    let uri = [api_host, "mediaplan"].join("/");
    opts = opts || {};
    let qs = serialize({
      page: parseInt(opts.page, 10) || 1,
      limit: parseInt(opts.limit, 10) || 10,
    });
    uri += !!qs.length ? "?" + qs : "";
    return requestJSON("GET", uri, null, true);
  }

  static Update(mediaplan, data) {
    let uri = [api_host, "mediaplan", mediaplan].join("/");
    return requestJSON("PUT", uri, data, true);
  }

  static Remove(mediaplan) {
    let uri = [api_host, "mediaplan", mediaplan].join("/");
    return requestJSON("DELETE", uri, null, true);
  }

}

export default MediaPlan