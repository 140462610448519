import React from "react";
import {connect} from "react-redux";
import {NavLink} from "react-router-dom";
import {Preloader} from "../components";
import {Media} from "../actions";
import {getAccount, getMediaFiles} from "../selectors";
import {I18n} from "../utils/i18n";
import {MediaFileCardList, MultiFileUploader, Pagination} from "../containers";
import qs from "query-string";
import {serialize} from "../utils/helpers";
import * as api from "../api";

// const default_type = "image/png,image/bmp,image/tiff,image/jpeg,application/pdf,video/mp4,application/x-matroska,.mkv";
const default_type = "*";

class MediaPageList extends React.Component {
  constructor(props) {
    super(props);

    let query = qs.parse(this.props.history.location.search);

    this.state = {
      total: 0,
      page: parseInt(query.page, 10) || 1,
      limit: parseInt(query.limit, 10) || 50,
      pages: 1,
      maxPages: 5,
      pending: true,
      file: null,
      mediaplans: null
    };
  }

  componentDidMount() {
    this.props.dispatch(Media.FileList({
      page: this.state.page,
      limit: this.state.limit
    }))
      .then((res) => {
        const pages = (res.total > this.state.limit) ? Math.ceil(res.total / this.state.limit) : 1;

        if (res.page > pages) return this.handlePageChange(pages);

        this.setState({...this.state, total: res.total, page: res.page, pages: pages, pending: false})
      })
      .catch(() => this.setState({pending: false}));
  }

  handlePageChange = (page) => {
    let query = qs.parse(this.props.history.location.search);
    query.page = page;
    this.props.history.push({...this.props.location, search: "?" + serialize(query || {})});

    this.props.dispatch(Media.FileList({
      page: page,
      limit: this.state.limit
    }))
      .then((res) => {
        const pages = (res.total > this.state.limit) ? Math.ceil(res.total / this.state.limit) : 1;

        if (res.page > pages) return this.handlePageChange(pages);

        this.props.history.push({...this.props.location, search: "?" + serialize(query || {})});
        this.setState({...this.state, total: res.total, page: res.page, pages: pages, pending: false})
      })
      .catch(() => this.setState({pending: false}));
  };

  handleRemoveCancel = () => {
    this.setState({mediaplans: null, file: null})
  }

  handleRemoveApply = () => {
    this.props.dispatch(Media.FileRemove(this.state.file))
      .then(() => this.setState({mediaplans: null, file: null}))
  }

  handleRemove = (file) => {
    api.Media.MediaPlanListByFile(file.id)
      .then((list) => {
        this.setState({mediaplans: list, file: file})
      })
  };

  handleEvent = () => {
    this.handlePageChange(this.state.page)
  };

  render() {
    if (this.state.pending) return (
      <section className="container d-flex justify-content-center align-items-center vh-100">
        <Preloader/>
      </section>
    );

    if (!!this.state.mediaplans) {
      return (
        <section className="container content">
          <div className="row justify-content-center">
            <div className="col-4 text-center">
              <div><h3>{I18n.t('key_MediaPageList_MediaRemoveFileConfirm', {file: this.state.file.meta.name})}</h3></div>
              {(this.state.mediaplans.length > 0)
                ? <div className="my-2 alert alert-warning"><h5>{I18n.t('key_MediaPageList_UseInMediaPlanList')}</h5></div>
                :
                <div className="my-2 alert alert-success"><h5>{I18n.t('key_MediaPageList_NotUseInMediaPlanList')}</h5></div>
              }
            </div>
          </div>

          {
            (this.state.mediaplans.length > 0) && (
              <div className="row justify-content-center">
                <div className="col-4">
                  <ol>
                    {this.state.mediaplans.map((item, index) => (
                      <li key={index}><NavLink to={`/mp/${item.meta.id}`} target="_blank">{item.meta.name}</NavLink>
                      </li>
                    ))}
                  </ol>
                </div>
              </div>
            )
          }

          <div className="row justify-content-center mt-2">
            <div className="col-4">
              <div className="clearfix">
                <div className="float-left">
                  <button className="btn btn-sm btn-secondary"
                          onClick={this.handleRemoveCancel}>{I18n.t('key_Cancel')}</button>
                </div>
                <div className="float-right">
                  <button className="btn btn-sm btn-danger"
                          onClick={this.handleRemoveApply}>{I18n.t('key_Remove')}</button>
                </div>
              </div>
            </div>
          </div>
        </section>
      )
    }

    const {files} = this.props;

    return (
      <section className="container content">

        <div className="subnav-panel">
          <div>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><NavLink to={"/"}>{I18n.t('key_Home')}</NavLink></li>
              <li className="breadcrumb-item active">{I18n.t('key_Media')}</li>
            </ol>
          </div>
        </div>

        <div className="row">
          <div className="col-9">
            <div className="card">
              <div className="card-body">
                <MediaFileCardList files={files}
                                   onRemove={this.handleRemove}/>

                <div className="mt-4">
                  <Pagination maxPages={this.state.maxPages}
                              currentPage={this.state.page}
                              numPages={this.state.pages}
                              change={this.handlePageChange}/>
                </div>
              </div>
            </div>
          </div>
          <div className="col-3">
            <div className="d-flex justify-content-center">
              <MultiFileUploader accept={default_type}
                                 onMessage={this.handleEvent}
                                 uploadURI={api.Media.FileUploadURI()}/>
            </div>
          </div>
        </div>

      </section>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    files: getMediaFiles(state),
    account: getAccount(state),
  }
};

export default connect(mapStateToProps)(MediaPageList);

