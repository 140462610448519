import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {getDeviceByID} from "../../selectors";
import PropTypes from "prop-types";
import I18n from "../../utils/i18n/lib/i18n";
import {DeviceFormRemove, DeviceFormUpdateGeneralInfo} from "../../containers";
import {Device} from "../../actions";

class DevicePartialSettings extends React.Component {

  constructor(props) {
    super(props);
    this.state = {}
  }

  handleUpdateSSL = (tls, ssl) => {

    let data = {tls: tls};
    if (!!tls) {
      data.ssl = {
        ca: ssl.ca,
        cert: ssl.cert,
        key: ssl.key
      }
    }

    const {device, dispatch} = this.props;
    return dispatch(Device.Update(device, data))
  };

  handleUpdateGeneralInfo = (name, address) => {
    let data = {name: name, address: address};
    const {device, dispatch} = this.props;
    return dispatch(Device.Update(device, data))
  };

  handleRemove = () => {
    const {device, dispatch} = this.props;
    return dispatch(Device.Remove(device))
      .then(() => this.props.history.push(`/d`))
  };

  render() {
    const {device} = this.props;

    return (
      <div>
        <div className="mb-4 border-bottom">
          <h2>{I18n.t('key_DevicePartialSettings_Title')}</h2>
        </div>

        <div className="row mb-4">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <h4 className="text-success">{I18n.t('key_DevicePartialSettings_GeneralSettings')}</h4>
                <div className="mt-4">
                  <DeviceFormUpdateGeneralInfo device={device} submit={this.handleUpdateGeneralInfo}/>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <h4 className="text-success">{I18n.t('key_DevicePartialSettings_DeleteDevice')}</h4>
                <span
                  className="text-warning">{I18n.t('key_DevicePartialSettings_ThisActionCannotBeUndone', {name: device.meta.serial})}</span>
                <div className="mt-4">
                  <DeviceFormRemove device={device} submit={this.handleRemove}/>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    )
  }
}

DevicePartialSettings.propTypes = {
  deviceID: PropTypes.string.isRequired,
};

const mapStateToProps = (state, props) => {
  return {
    device: getDeviceByID(state, props.deviceID),
  }
};

export default withRouter(connect(mapStateToProps)(DevicePartialSettings));
