import React from "react"
import PropTypes from "prop-types"
import {Button} from "../containers";
import {I18n} from "../utils/i18n";

class MediaPlanFormCreate extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      data: {
        name: "",
      },
      error: {
        name: "",
        form: ""
      },
      pending: false
    };


    this.handleSendEvent = () => {
      if (typeof this.props.onChange !== "function") return;

      const name = this.props.name || "schedule";
      let event = new Event(name);

      Object.defineProperty(event, 'target', {
        writable: false,
        value: {name: name, value: this.state.data},
      });

      this.props.onChange(event)
    }
  }

  handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let error = "";

    let patch = {};
    switch (name) {
      case "name":
        patch = {data: {...this.state.data, [name]: value}};
        break;
      default:
    }

    this.setState({
      ...patch,
      error: {...this.state.error, [name]: error},
      pending: false,
      success: false
    }, this.handleSendEvent)
  };

  handleCancel = (e) => {
    this.props.cancel()
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({pending: true});
    let data = this.state.data;

    return Promise.resolve(this.props.submit(data.name))
      .then(this.handleSuccess, this.handleError)
  };

  handleSuccess = (res) => {
    this.setState({pending: false});
  };

  handleError = (errors) => {
    let e = this.state.error;
    if (errors.status === "Bad Request") e.form = errors.message;
    if (errors.message === "Bad name parameter") e.name = errors.message;

    this.setState({error: e, pending: false});
  };

  handleDisabled = () => {
    return (
      this.state.pending || !this.state.data.name
    )
  };

  render() {

    return (
      <form>

        <div className={`form-group ${!!this.state.error.form ? "has-danger" : ""}`}>
          {this.state.error.form && <span className="badge badge-danger">{this.state.error.form}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="nameInput">{I18n.t('key_MediaPlanFormCreate_LabelName')}</label>
          <input type="text"
                 name="name"
                 id="nameInput"
                 className={`form-control form-control-border ${this.state.error.name ? "is-invalid" : ""}`}
                 maxLength={100}
                 placeholder={I18n.t('key_MediaPlanFormCreate_PlaceholderName')}
                 value={this.state.data.name}
                 onChange={this.handleChange}
                 required
          />
          <div className="invalid-feedback">{this.state.error.name}</div>
        </div>

        {
          (!!this.props.cancel && !!this.props.submit) && (
            <div className="pt-4 d-flex justify-content-between">
              <div className="col-6 pl-0 text-left">
                <button className="btn btn-inverse"
                        onClick={this.handleCancel}
                        type="button">
                  {I18n.t('key_Cancel')}
                </button>
              </div>

              <div className="col-6 pr-0 text-right">
                <Button type='submit'
                        className='btn btn-primary text-capitalize'
                        disabled={this.handleDisabled()}
                        submit={this.handleSubmit}
                        main={I18n.t('key_Create')}
                />
              </div>

            </div>
          )
        }

        {
          (!this.props.cancel && !!this.props.submit) && (
            <div className="pt-4 d-flex justify-content-center">
              <Button type='submit'
                      className='btn btn-primary text-capitalize'
                      disabled={this.handleDisabled()}
                      submit={this.handleSubmit}
                      main={I18n.t('key_Create')}/>
            </div>
          )
        }

      </form>
    )
  }
}

MediaPlanFormCreate.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  submit: PropTypes.func,
  cancel: PropTypes.func
};

export default MediaPlanFormCreate;