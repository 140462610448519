import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {getDeviceByID} from "../../selectors";
import PropTypes from "prop-types";
import {I18n} from "../../utils/i18n";

class DevicePartialOverview extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>

        <div className="mb-4 border-bottom">
          <h2>{I18n.t('key_Dashboard')}</h2>
        </div>

        <div className="row">
          <div className="col-12">
            <img src="../images/dashboard.png" alt="" style={{width: "inherit"}}/>
          </div>
        </div>
      </div>
    )
  }
}

DevicePartialOverview.propTypes = {
  deviceID: PropTypes.string.isRequired,
};

const mapStateToProps = (state, props) => {
  return {
    device: getDeviceByID(state, props.deviceID),
  }
};

export default withRouter(connect(mapStateToProps)(DevicePartialOverview));
