import {Device} from "../../../api";
import {DEVICE_SCHEDULE_UPDATE_FAILURE, DEVICE_SCHEDULE_UPDATE_SUCCESS} from "../../../constants";
import {normalize} from "normalizr";
import deviceScheduleSchema from "../../../schemas/device_schedule";


const SuccessAction = (payload) => ({
  type: DEVICE_SCHEDULE_UPDATE_SUCCESS,
  payload: normalize([payload], [deviceScheduleSchema])
});

const FailureAction = (payload) => ({
  type: DEVICE_SCHEDULE_UPDATE_FAILURE,
  payload
});

export default (device, data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Device.ScheduleUpdate(device, data)
      .then(response => {
        dispatch(SuccessAction(response));
        resolve(response);
      })
      .catch(error => {
        dispatch(FailureAction({error}));
        reject(error);
      });
  });
};