import {TAG_LIST_FETCH_FAILURE, TAG_LIST_FETCH_SUCCESS,} from "../constants";


export const tagReducer = {
  [TAG_LIST_FETCH_SUCCESS]: (state, payload) => {
    let newState = state.removeIn(["entities", "tags"]);
    return newState.mergeDeep(payload)
  },
  [TAG_LIST_FETCH_FAILURE]: (state) => {
    return state;
  },
};
