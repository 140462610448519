// ==============================================================================================
// SESSION ======================================================================================
// ==============================================================================================
export const SESSION_CREATE_SUCCESS = 'SESSION_CREATE_SUCCESS';
export const SESSION_CREATE_FAILURE = 'SESSION_CREATE_FAILURE';

export const SESSION_SET_SUCCESS = 'SESSION_SET_SUCCESS';

export const SESSION_REMOVE_SUCCESS = 'SESSION_REMOVE_SUCCESS';

// ==============================================================================================
// ACCOUNT ======================================================================================
// ==============================================================================================
export const ACCOUNT_CREATE_SUCCESS = 'ACCOUNT_CREATE_SUCCESS';
export const ACCOUNT_CREATE_FAILURE = 'ACCOUNT_CREATE_FAILURE';

export const ACCOUNT_FETCH_SUCCESS = 'ACCOUNT_FETCH_SUCCESS';
export const ACCOUNT_FETCH_FAILURE = 'ACCOUNT_FETCH_FAILURE';

export const ACCOUNT_LIST_FETCH_SUCCESS = 'ACCOUNT_LIST_FETCH_SUCCESS';
export const ACCOUNT_LIST_FETCH_FAILURE = 'ACCOUNT_LIST_FETCH_FAILURE';

export const ACCOUNT_UPDATE_SUCCESS = 'ACCOUNT_UPDATE_SUCCESS';
export const ACCOUNT_UPDATE_FAILURE = 'ACCOUNT_UPDATE_FAILURE';

export const ACCOUNT_CURRENT_SET_SUCCESS = 'ACCOUNT_CURRENT_SET_SUCCESS';

export const ACCOUNT_PROFILE_FETCH_SUCCESS = 'ACCOUNT_PROFILE_FETCH_SUCCESS';
export const ACCOUNT_PROFILE_FETCH_FAILURE = 'ACCOUNT_PROFILE_FETCH_FAILURE';

export const ACCOUNT_PROFILE_UPDATE_SUCCESS = 'ACCOUNT_PROFILE_UPDATE_SUCCESS';
export const ACCOUNT_PROFILE_UPDATE_FAILURE = 'ACCOUNT_PROFILE_UPDATE_FAILURE';

export const ACCOUNT_PASSWORD_UPDATE_SUCCESS = 'ACCOUNT_PASSWORD_UPDATE_SUCCESS';
export const ACCOUNT_PASSWORD_UPDATE_FAILURE = 'ACCOUNT_PASSWORD_UPDATE_FAILURE';

export const ACCOUNT_PASSWORD_RECOVERY_SUCCESS = 'ACCOUNT_PASSWORD_RECOVERY_SUCCESS';
export const ACCOUNT_PASSWORD_RECOVERY_FAILURE = 'ACCOUNT_PASSWORD_RECOVERY_FAILURE';

export const ACCOUNT_PASSWORD_CHANGE_SUCCESS = 'ACCOUNT_PASSWORD_CHANGE_SUCCESS';
export const ACCOUNT_PASSWORD_CHANGE_FAILURE = 'ACCOUNT_PASSWORD_CHANGE_FAILURE';

// ==============================================================================================
// DEVICE =======================================================================================
// ==============================================================================================
export const DEVICE_LIST_FETCH_SUCCESS = 'DEVICE_LIST_FETCH_SUCCESS';
export const DEVICE_LIST_FETCH_FAILURE = 'DEVICE_LIST_FETCH_FAILURE';

export const DEVICE_FETCH_SUCCESS = 'DEVICE_FETCH_SUCCESS';
export const DEVICE_FETCH_FAILURE = 'DEVICE_FETCH_FAILURE';
export const DEVICE_UPDATE_SUCCESS = 'DEVICE_UPDATE_SUCCESS';
export const DEVICE_UPDATE_FAILURE = 'DEVICE_UPDATE_FAILURE';
export const DEVICE_CREATE_SUCCESS = 'DEVICE_CREATE_SUCCESS';
export const DEVICE_CREATE_FAILURE = 'DEVICE_CREATE_FAILURE';
export const DEVICE_REMOVE_SUCCESS = 'DEVICE_REMOVE_SUCCESS';
export const DEVICE_REMOVE_FAILURE = 'DEVICE_REMOVE_FAILURE';

// ==============================================================================================
// DEVICE SCHEDULE ==============================================================================
// ==============================================================================================
export const DEVICE_SCHEDULE_FETCH_SUCCESS = 'DEVICE_SCHEDULE_FETCH_SUCCESS';
export const DEVICE_SCHEDULE_FETCH_FAILURE = 'DEVICE_SCHEDULE_FETCH_FAILURE';
export const DEVICE_SCHEDULE_UPDATE_SUCCESS = 'DEVICE_SCHEDULE_UPDATE_SUCCESS';
export const DEVICE_SCHEDULE_UPDATE_FAILURE = 'DEVICE_SCHEDULE_UPDATE_FAILURE';

// ==============================================================================================
// MEDIA FILE ===================================================================================
// ==============================================================================================
export const MEDIA_FILE_LIST_FETCH_SUCCESS = 'MEDIA_FILE_LIST_FETCH_SUCCESS';
export const MEDIA_FILE_LIST_FETCH_FAILURE = 'MEDIA_FILE_LIST_FETCH_FAILURE';

export const MEDIA_FILE_REMOVE_SUCCESS = 'MEDIA_FILE_REMOVE_SUCCESS';
export const MEDIA_FILE_REMOVE_FAILURE = 'MEDIA_FILE_REMOVE_FAILURE';

// ==============================================================================================
// MEDIA PLAN ===================================================================================
// ==============================================================================================
export const MEDIA_PLAN_LIST_FETCH_SUCCESS = 'MEDIA_PLAN_LIST_FETCH_SUCCESS';
export const MEDIA_PLAN_LIST_FETCH_FAILURE = 'MEDIA_PLAN_LIST_FETCH_FAILURE';

export const MEDIA_PLAN_FETCH_SUCCESS = 'MEDIA_PLAN_FETCH_SUCCESS';
export const MEDIA_PLAN_FETCH_FAILURE = 'MEDIA_PLAN_FETCH_FAILURE';
export const MEDIA_PLAN_UPDATE_SUCCESS = 'MEDIA_PLAN_UPDATE_SUCCESS';
export const MEDIA_PLAN_UPDATE_FAILURE = 'MEDIA_PLAN_UPDATE_FAILURE';
export const MEDIA_PLAN_CREATE_SUCCESS = 'MEDIA_PLAN_CREATE_SUCCESS';
export const MEDIA_PLAN_CREATE_FAILURE = 'MEDIA_PLAN_CREATE_FAILURE';
export const MEDIA_PLAN_REMOVE_SUCCESS = 'MEDIA_PLAN_REMOVE_SUCCESS';
export const MEDIA_PLAN_REMOVE_FAILURE = 'MEDIA_PLAN_REMOVE_FAILURE';

// ==============================================================================================
// TAG ==========================================================================================
// ==============================================================================================
export const TAG_LIST_FETCH_SUCCESS = 'TAG_LIST_FETCH_SUCCESS';
export const TAG_LIST_FETCH_FAILURE = 'TAG_LIST_FETCH_FAILURE';