import {createReducer} from "../utils/reducer";
import * as storage from "../utils/storage";
import {fromJS} from 'immutable'

import {accountReducer} from "./account";
import {sessionReducer} from "./session";
import {deviceReducer} from "./device";
import {mediaReducer} from "./media";
import {mediaPlanReducer} from "./mediaplan";
import {tagReducer} from "./tag";

const initialState = fromJS(
  {
    session: {
      token: storage.LocalStorage().get('token')
    }
  }
);

const reducers = Object.assign(accountReducer, sessionReducer, deviceReducer, mediaReducer, mediaPlanReducer, tagReducer);

export default createReducer(initialState, reducers)