import React from "react"
import PropTypes from "prop-types"
import {I18n} from "../utils/i18n";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {TimePicker} from "antd";
import moment from "moment";

import "antd/dist/antd.css";
import {getSeconds, parseTime} from "../utils/helpers";

const timeFormat = 'HH:mm';

const convertDuration = (d) => {
  let res = "00h00m00s";
  let match = d.split(":");
  if (match.length === 3) res = match[0] + "h" + match[1] + "m" + match[2] + "s";
  if (match.length === 2) res = match[0] + "h" + match[1] + "m00s";
  if (match.length === 1) res = match[0] + "h00m00s";
  return res;
};
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: "5px",
  margin: "5px 0 5px 0",
  border: `1px dashed ${isDragging ? 'lightgreen' : 'grey'}`,

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  border: `1px dashed ${isDraggingOver ? 'lightblue' : 'lightgrey'}`,
  padding: grid,
  width: "100%",
});

class MediaPlanFormClone extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      data: {
        items: (props.data || []).slice(),
        duration: parseTime("00:30:00"),
      },
      mapTags: props.mapTags || [],
      count: 0,
    };
  }

  componentDidMount() {
    this.preCalculate();
  }

  showTags = (list) => {
    let res = [];
    list.forEach(item => {
      res.push(this.state.mapTags[item])
    })
    return res.join(", ");
  }

  handleDateChange = (name, value) => {
    let date = !!value ? value.toDate() : null;
    this.setState({data: {...this.state.data, [name]: date}}, this.preCalculate)
  };

  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    this.setState({
      data: {
        ...this.state.data,
        items: reorder(this.state.data.items, result.source.index, result.destination.index),
      }
    });
  }

  handleCancel = (e) => {
    this.props.onCancel()
  };

  preCalculate = () => {
    let result = [];
    let duration = 0
    let duration_limit = getSeconds(convertDuration(new Date(this.state.data.duration).toLocaleTimeString()))
    let items = this.state.data.items.slice()

    while (true) {
      const item = items.shift()

      if (duration_limit < (duration + getSeconds(convertDuration(item.duration)))) {
        break;
      }

      duration += getSeconds(convertDuration(item.duration));
      result.push({...item})
      items.push(item)
    }

    this.setState({count: result.length})
  }

  handleSubmit = (e) => {
    e.preventDefault();

    let result = [];
    let duration = 0
    let duration_limit = getSeconds(convertDuration(new Date(this.state.data.duration).toLocaleTimeString()))
    let items = this.state.data.items.slice()

    while (true) {
      const item = items.shift()

      if (duration_limit < (duration + getSeconds(convertDuration(item.duration)))) {
        break;
      }

      duration += getSeconds(convertDuration(item.duration));
      result.push({...item})
      items.push(item)
    }

    this.props.onSubmit(result)
  };

  render() {

    window.$(() => window.$('[data-toggle="popover"]').popover({trigger: 'hover'}));

    return (
      <div>

        <div className="form-group">
          <label htmlFor="nameInput"
                 className="col-form-label">{I18n.t('key_MediaPlanFormClone_Duration')}</label>
          <div className="d-flex">
            <div className="flex-grow-1">
              <TimePicker format={timeFormat}
                          inputReadOnly={true}
                          allowClear={false}
                          value={moment(this.state.data.duration, timeFormat)}
                          defaultValue={moment('00:00', timeFormat)}
                          defaultOpenValue={moment('00:00', timeFormat)}
                          onChange={val => this.handleDateChange("duration", val)}/>
            </div>
            {
              (this.state.count > 0)
                ? (
                  <div className="ml-2 alert alert-info" style={{padding: "4px"}}>
                    {I18n.t('key_MediaPlanFormClone_InfoMessage', {count: this.state.count})}
                  </div>
                )
                : (
                  <div className="ml-2 alert alert-warning" style={{padding: "4px"}}>
                    {I18n.t('key_MediaPlanFormClone_WarningMessage')}
                  </div>
                )
            }
          </div>
        </div>

        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {this.state.data.items.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            {
                              (item.type === "default") && (
                                <span>{index + 1}. {I18n.t('key_MediaPlanFormClone_DefaultTitle')}</span>
                              )
                            }

                            {
                              (item.type !== "commercial" && item.type !== "external") && (
                                <span>{index + 1}. {item.filename}</span>
                              )
                            }

                            {
                              (item.type === "commercial") && (
                                <div>{index + 1}. {I18n.t('key_MediaPlanFormClone_CommercialTimeTitle')} <i
                                  className="fas fa-info-circle"
                                  data-toggle="popover"
                                  title={I18n.t('key_MediaPlanFormClone_Tags')}
                                  data-content={this.showTags(item.tags)}/>
                                </div>
                              )
                            }

                            {
                              (item.type === "external") && (
                                <div>{index + 1}. {I18n.t('key_MediaPlanFormClone_ExternalSourceTimeTitle')}</div>
                              )
                            }
                          </div>
                          <div>{item.duration}</div>
                        </div>

                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <div className="pt-4 d-flex justify-content-between">
          <div className="col-6 pl-0 text-left">
            <button className="btn btn-inverse"
                    onClick={this.handleCancel}
                    type="button">
              {I18n.t('key_Cancel')}
            </button>
          </div>

          <div className="col-6 pr-0 text-right">
            <button type='submit'
                    className='btn btn-primary text-capitalize'
                    disabled={!this.state.count}
                    onClick={this.handleSubmit}>
              {I18n.t('key_Add')}
            </button>
          </div>

        </div>

      </div>
    )
  }
}

MediaPlanFormClone.propTypes = {
  mapTags: PropTypes.array,
  data: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default MediaPlanFormClone;
