import {createSelector} from 'reselect/lib';

const getDeviceScheduleMap = (state, opts = null) => {
  let items = state.data.getIn(['entities', 'device_schedules']);
  if (!items) return null;

  if (!!opts) {
    if (!!opts.device) {
      items = items.filter(item => item.get("meta").get("device") === opts.device);
    }
  }

  return items;
};

const getDeviceScheduleItemByDeviceID = (state, id) => {
  const items = getDeviceScheduleMap(state);

  return !!items
    ? items.find(item => item.get('meta').get('device') === id)
    : null
};

export const getDeviceScheduleByDeviceID = createSelector(
  [getDeviceScheduleItemByDeviceID],
  (item) => !!item ? item.toJS() : null
);