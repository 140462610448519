import React from "react";
import {connect} from "react-redux";
import {getAccount} from "../selectors";
import {Device} from "../actions";
import {DeviceFormCreate} from "../containers";
import {I18n} from "../utils/i18n";

class DevicePageCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pending: true,
    };
  }

  handlerCreate = (name, serial, address) => {

    const data = {
      name: name,
      serial: serial,
      address: address,
    };

    return this.props.dispatch(Device.Create(data))
      .then((device) => this.props.history.push(`/d/${device.meta.id}`))
  };

  handleCancel = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <section className="container vh-100">
        <div className="row h-100 justify-content-center">
          <div className="col-md-6 col-12 my-auto">

            <div className="card">

              <div className="card-title text-center">
                <h2>{I18n.t('key_PageDeviceCreate_NewDeviceTitle')}</h2>
              </div>

              <div className="card-body">
                <DeviceFormCreate submit={this.handlerCreate}
                                  cancel={this.handleCancel}/>
              </div>

            </div>

          </div>
        </div>
      </section>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    account: getAccount(state),
  }
};

export default connect(mapStateToProps)(DevicePageCreate);

