import React from 'react';
import PropTypes from "prop-types"
import {I18n} from "../utils/i18n";
import {TimePicker} from "antd";
import {parseTime} from "../utils/helpers";

import moment from "moment";

import "antd/dist/antd.css";

const timeFormat = 'HH:mm';

class MediaPlanFormExternalTimeFormChange extends React.Component {

  constructor(props) {
    super(props);

    const data = props.data || {};

    this.state = {
      data: {
        id: data.id || null,
        duration: (!!data.duration) ? parseTime(data.duration) : parseTime("00:00:00"),
      },
      error: {
        form: ""
      },
      pending: false
    };
  }

  handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    let value = target.value;
    this.setState({data: {...this.state.data, [name]: value}})
  };

  handleDateChange = (name, value) => {
    let date = !!value ? value.toDate() : null;
    this.setState({data: {...this.state.data, [name]: date}})
  };

  handleCancel = (e) => {
    this.props.onCancel()
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({pending: true});
    let data = {...this.state.data};
    data.duration = new Date(data.duration).toLocaleTimeString();
    this.props.onSubmit(data)
  };

  handleDisabled = () => {
    return (
      this.state.pending
    )
  };

  render() {
    return (
      <form>

        <div className={`form-group ${!!this.state.error.form ? "has-danger" : ""}`}>
          {this.state.error.form && <span className="badge badge-danger">{this.state.error.form}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="nameInput" className="col-form-label">Продолжительность (в часах)</label>
          <div>
            <TimePicker format={timeFormat}
                        inputReadOnly={true}
                        allowClear={false}
                        defaultValue={moment('00:00', timeFormat)}
                        value={moment(this.state.data.duration, timeFormat)}
                        defaultOpenValue={moment('00:00', timeFormat)}
                        onChange={val => this.handleDateChange("duration", val)}/>
          </div>
        </div>

        <div className="clearfix my-4">
          <div className="float-left">
            <button className="btn btn-secondary"
                    onClick={this.handleCancel}>
              {I18n.t('key_Cancel')}
            </button>
          </div>
          <div className="float-right">
            <button className="btn btn-primary"
                    onClick={this.handleSubmit}
                    disabled={this.handleDisabled()}>
              {I18n.t('key_Apply')}
            </button>
          </div>
        </div>

      </form>
    )
  }
}

MediaPlanFormExternalTimeFormChange.propTypes = {
  name: PropTypes.string,
  limit: PropTypes.number,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default MediaPlanFormExternalTimeFormChange;