import React, {Component} from 'react';
import {connect} from "react-redux";
import {getMediaPlanByID} from "../selectors";
import {MediaPlan} from "../actions";
import {MediaPlanFormUpdate} from "../containers";
import {I18n} from "../utils/i18n";
import {NavLink} from "react-router-dom";
import {Preloader} from "../components";

class MediaPlanPageInfo extends Component {

  constructor(props) {
    super(props);
    this.state = {
      pending: true,
    };
  }

  componentDidMount() {
    this.props.dispatch(MediaPlan.Fetch(this.props.match.params.mediaplan))
      .then(() => this.setState({pending: false}))
      .catch(() => this.setState({pending: false}));
  }

  handleRemove = () => {
    return this.props.dispatch(MediaPlan.Remove(this.props.mediaplan))
      .then(() => this.props.history.push(`/mp`))
  };
  handleSubmit = (data) => {
    return this.props.dispatch(MediaPlan.Update(this.props.mediaplan, data))
  };
  handleBack = () => this.props.history.push(`/mp`);

  render() {

    const {mediaplan} = this.props;

    if (this.state.pending || !mediaplan || !mediaplan.id) return (
      <section className="container d-flex justify-content-center align-items-center vh-100">
        <Preloader/>
      </section>
    );

    return (
      <section className="container content vh-100">

        <div className="mb-4">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><NavLink to={"/"}>{I18n.t('key_Home')}</NavLink></li>
            <li className="breadcrumb-item"><NavLink to={"/mp"}>{I18n.t('key_MediaPlans')}</NavLink></li>
            <li className="breadcrumb-item active">{mediaplan.meta.name}</li>
          </ol>
        </div>

        <div>
          <MediaPlanFormUpdate mediaplan={mediaplan}
                               onSubmit={this.handleSubmit}
                               onRemove={this.handleRemove}
                               onCancel={this.handleBack}/>
        </div>

      </section>
    );

  }
}

const mapStateToProps = (state, props) => {
  return {
    mediaplan: getMediaPlanByID(state, props.match.params.mediaplan),
  }
};

export default connect(mapStateToProps)(MediaPlanPageInfo);
