import {
  MEDIA_FILE_LIST_FETCH_FAILURE,
  MEDIA_FILE_LIST_FETCH_SUCCESS,
  MEDIA_FILE_REMOVE_SUCCESS,
  MEDIA_FILE_REMOVE_FAILURE,
} from "../constants";


export const mediaReducer = {
  [MEDIA_FILE_LIST_FETCH_SUCCESS]: (state, payload) => {
    let newState = state.removeIn(["entities", "media_files"]);
    return newState.mergeDeep(payload)
  },
  [MEDIA_FILE_LIST_FETCH_FAILURE]: (state) => {
    return state;
  },
  [MEDIA_FILE_REMOVE_SUCCESS]: (state, payload) => {
    return state.mergeDeep(payload)
  },
  [MEDIA_FILE_REMOVE_FAILURE]: (state) => {
    return state;
  },
};
