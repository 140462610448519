import React from "react"
import PropTypes from "prop-types"
import {I18n} from "../utils/i18n";
import {connect} from "react-redux";
import {parseTime} from "../utils/helpers";
import {TimePicker} from "antd";
import moment from "moment-timezone";

import "antd/dist/antd.css";

const timeFormat = 'HH:mm';

class MediaPlanFormGeneralInfo extends React.Component {

  constructor(props) {
    super(props);

    const data = props.mediaplan || {};

    this.state = {
      data: {
        name: data.meta.name,
        start_time: parseTime(props.mediaplan.spec.start_time),
        end_time: parseTime(props.mediaplan.spec.end_time),
      },
      error: {
        name: "",
        start_time: "",
        end_time: "",
        form: ""
      },
      pending: false
    };


    this.handleSendEvent = () => {
      if (typeof this.props.onChange !== "function") return;

      const name = this.props.name || "mediaplan";
      let event = new Event(name);

      Object.defineProperty(event, 'target', {
        writable: false,
        value: {name: name, value: this.state.data},
      });

      this.props.onChange(event)
    }
  }

  handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let error = "";

    let patch = {};
    switch (name) {
      case "name":
        patch = {data: {...this.state.data, [name]: value}};
        break;
      default:
    }

    this.setState({
      ...patch,
      error: {...this.state.error, [name]: error},
      pending: false,
      success: false
    }, this.handleSendEvent)
  };

  handleDateChange = (name, value) => {
    let date = !!value ? new Date(value.utc().toDate()) : null;
    this.setState({data: {...this.state.data, [name]: date}}, this.handleSendEvent)
  };

  handleCancel = (e) => {
    this.props.cancel()
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({pending: true});
    let data = this.state.data;

    return Promise.resolve(this.props.submit(data.name, data.start_time, data.end_time))
      .then(this.handleSuccess, this.handleError)
  };

  handleSuccess = (res) => {
    this.setState({pending: false});
  };

  handleError = (errors) => {
    let e = this.state.error;
    if (errors.status === "Bad Request") e.form = errors.message;
    if (errors.message === "Bad name parameter") e.name = errors.message;
    if (errors.message === "Bad start time parameter") e.start_time = errors.message;
    if (errors.message === "Bad end time parameter") e.end_time = errors.message;

    this.setState({error: e, pending: false});
  };

  handleDisabled = () => {
    return (
      this.state.pending || !this.state.data.name
    )
  };

  render() {
    return (
      <form>

        <div className={`form-group ${!!this.state.error.form ? "has-danger" : ""}`}>
          {this.state.error.form && <span className="badge badge-danger">{this.state.error.form}</span>}
        </div>

        <div>

          <div className="form-group">
            <label htmlFor="nameInput"
                   className="col-form-label">{I18n.t('key_DeviceScheduleFormUpdate_LabelName')}</label>
            <input type="text"
                   className={`form-control ${this.state.error.name ? 'is-invalid' : ''}`}
                   id="nameInput"
                   placeholder={I18n.t('key_DeviceScheduleFormUpdate_PlaceholderName')}
                   value={this.state.data.name}
                   onChange={this.handleChange}
                   name="name"/>
            <div className="invalid-feedback"/>
          </div>

          <div className="form-group">
            <label htmlFor="nameInput"
                   className="col-form-label">{I18n.t('key_DeviceScheduleFormUpdate_TimeInterval')}</label>
            <div>

              <TimePicker format={timeFormat}
                          inputReadOnly={true}
                          allowClear={false}
                          defaultValue={moment('00:00', timeFormat)}
                          value={moment(this.state.data.start_time)}
                          onChange={val => this.handleDateChange("start_time", val)}/>

              <TimePicker format={timeFormat}
                          inputReadOnly={true}
                          allowClear={false}
                          defaultValue={moment('00:00', timeFormat)}
                          value={moment(this.state.data.end_time)}
                          onChange={val => this.handleDateChange("end_time", val)}/>

            </div>
          </div>
        </div>

      </form>
    )
  }
}

MediaPlanFormGeneralInfo.propTypes = {
  name: PropTypes.string,
  mediaplan: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  submit: PropTypes.func,
  cancel: PropTypes.func
};

const mapStateToProps = (state, props) => props;

export default connect(mapStateToProps)(MediaPlanFormGeneralInfo);
