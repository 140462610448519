import React from "react"
import PropTypes from "prop-types"
import Validator from "../utils/validator"
import {Button} from "../containers";
import {I18n} from "../utils/i18n";
import {AddressSuggestions} from 'react-dadata';

import 'react-dadata/dist/react-dadata.css';

const dadata_key = window._env_.DADATA_KEY;

class DeviceFormCreate extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      data: {
        name: "",
        address: "",
        tls: false,
        ssl: {
          ca: "",
          cert: "",
          key: "",
        },
      },
      error: {
        tls: "",
        ca: "",
        cert: "",
        key: "",
        form: ""
      },
      pending: false
    };


    this.handleSendEvent = () => {
      if (typeof this.props.onChange !== "function") return;

      const name = this.props.name || "device";
      let event = new Event(name);

      Object.defineProperty(event, 'target', {
        writable: false,
        value: {name: name, value: this.state.data},
      });

      this.props.onChange(event)
    }
  }

  handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let error = "";

    if (name === "serial") error = Validator.NameField(value);

    let patch = {};
    switch (name) {
      case "name":
      case "serial":
      case "tls":
        patch = {data: {...this.state.data, [name]: value}};
        break;
      case "ca":
      case"cert":
      case "key":
        patch = {data: {...this.state.data, ssl: {...this.state.data.ssl, [name]: value}}};
        break;
      default:
    }

    this.setState({
      ...patch,
      error: {...this.state.error, [name]: error},
      pending: false,
      success: false
    }, this.handleSendEvent)
  };

  handleDChange = (data) => {
    this.setState({data: {...this.state.data, address: data.value}});
  }

  handleCancel = (e) => {
    this.props.cancel()
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({pending: true});
    let data = this.state.data;

    return Promise.resolve(this.props.submit(data.name, data.serial, data.address))
      .then(this.handleSuccess, this.handleError)
  };

  handleSuccess = (res) => {
    this.setState({pending: false});
  };

  handleError = (errors) => {
    let e = this.state.error;
    if (errors.status === "Bad Request") e.form = errors.message;
    if (errors.status === "Not Found") e.form = errors.message;
    if (errors.message === "Bad name parameter") e.name = errors.message;
    if (errors.message === "Bad serial parameter") e.serial = errors.message;

    this.setState({error: e, pending: false});
  };

  handleDisabled = () => {
    return (
      this.state.pending
      || !this.state.data.name
      || !this.state.data.serial
      || (!!this.state.data.tls && (!this.state.data.ssl.ca || !this.state.data.ssl.cert || !this.state.data.ssl.key))
    )
  };

  render() {

    return (
      <form>

        <div className={`form-group ${!!this.state.error.form ? "has-danger" : ""}`}>
          {this.state.error.form && <span className="badge badge-danger">{this.state.error.form}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="nameInput">{I18n.t('key_DeviceFormCreate_LabelName')}</label>
          <input type="text"
                 name="name"
                 id="nameInput"
                 className={`form-control form-control-border ${this.state.error.name ? "is-invalid" : ""}`}
                 maxLength={100}
                 placeholder={I18n.t('key_DeviceFormCreate_PlaceholderName')}
                 value={this.state.data.name}
                 onChange={this.handleChange}
                 required
          />
          <div className="invalid-feedback">{this.state.error.name}</div>
        </div>

        <div className="form-group">
          <label htmlFor="serialInput">{I18n.t('key_DeviceFormCreate_LabelSerialName')}</label>
          <input type="text"
                 name="serial"
                 id="serialInput"
                 maxLength={100}
                 className={`form-control form-control-border ${this.state.error.serial ? "is-invalid" : ""}`}
                 placeholder={I18n.t('key_DeviceFormCreate_PlaceholderSerialName')}
                 value={this.state.data.serial}
                 onChange={this.handleChange}
                 required
          />
          <div className="invalid-feedback">{this.state.error.serial}</div>
        </div>

        <div className="form-group">
          <label htmlFor="addressInput">{I18n.t('key_DeviceFormCreate_LabelAddress')}</label>
          <AddressSuggestions token={dadata_key}
                              id="addressInput"
                              name="address"
                              value={this.state.data.address}
                              delay={500}
                              minChars={3}
                              onChange={this.handleDChange}/>
          <div className="invalid-feedback">{this.state.error.address}</div>
        </div>

        {
          (!!this.props.cancel && !!this.props.submit) && (
            <div className="pt-4 d-flex justify-content-between">
              <div className="col-6 pl-0 text-left">
                <button className="btn btn-inverse"
                        onClick={this.handleCancel}
                        type="button">
                  {I18n.t('key_Cancel')}
                </button>
              </div>

              <div className="col-6 pr-0 text-right">
                <Button type='submit'
                        className='btn btn-primary text-capitalize'
                        disabled={this.handleDisabled()}
                        submit={this.handleSubmit}
                        main={I18n.t('key_DeviceFormCreate_ButtonCreate')}
                />
              </div>

            </div>
          )
        }

        {
          (!this.props.cancel && !!this.props.submit) && (
            <div className="pt-4 d-flex justify-content-center">
              <Button type='submit'
                      className='btn btn-primary text-capitalize'
                      disabled={this.handleDisabled()}
                      submit={this.handleSubmit}
                      main={I18n.t('key_DeviceFormCreate_ButtonCreate')}/>
            </div>
          )
        }

      </form>
    )
  }
}

DeviceFormCreate.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  submit: PropTypes.func,
  cancel: PropTypes.func
};

export default DeviceFormCreate;
