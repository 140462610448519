import React from "react";
import {connect} from "react-redux";
import {NavLink, withRouter} from "react-router-dom";
import {Preloader} from "../components";
import {Device} from "../actions";
import {getAccount, getDeviceByID} from "../selectors";
import {I18n} from "../utils/i18n";
import {capitalizeFirstLetter} from "../utils/helpers";
import {DevicePartialOverview, DevicePartialSchedule, DevicePartialSettings} from "./Device";


class DevicePageInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pending: true,
    };
  }

  componentDidMount() {
    const {device} = this.props.match.params;
    Promise.all([
      this.props.dispatch(Device.Fetch(device)),
      this.props.dispatch(Device.Schedule.Fetch(device))
    ])
      .then(() => this.setState({pending: false}))
      .catch((e) => {
        if (e.code === 404) this.props.history.push(`/d`)
      });
  }

  handleChangePath = (e, url) => {
    e.preventDefault();
    this.props.history.push(url);
  };

  render() {
    const {match, device} = this.props;

    if (this.state.pending || !device || !device.id) return (
      <section className="container d-flex justify-content-center align-items-center vh-100">
        <Preloader/>
      </section>
    );

    const hash = window.location.hash.slice(1);
    const tabs = {
      "schedule": <DevicePartialSchedule deviceID={device.id}/>,
      "settings": <DevicePartialSettings deviceID={device.id}/>,
    };
    const tabIndex = Object.keys(tabs).indexOf(hash);

    return (
      <section className="container content">

        <div className="mb-4">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><NavLink to={"/"}>{I18n.t('key_Home')}</NavLink></li>
            <li className="breadcrumb-item"><NavLink to={"/d"}>{I18n.t('key_Devices')}</NavLink></li>
            <li className="breadcrumb-item active">
              {device.meta.name} <span
              className={`badge ${!!device.status.online ? "badge-success" : "badge-primary"}`}>{device.status.online ? "online" : "offline"}</span>
            </li>
          </ol>
        </div>

        <div>

          <div className="row">
            <div className="col-3">
              <ul className="nav flex-column nav-pills">

                <span className={`nav-link py-3 cursor-pointer ${(tabIndex === -1) ? 'active' : ''}`}
                      onClick={e => this.handleChangePath(e, match.url)}>
                  {I18n.t(`key_DevicePageInfo_TabOverview`)}
                </span>

                {
                  Object.keys(tabs).map((tab, index) =>
                    <span key={index}
                          className={`nav-link py-3 cursor-pointer ${(tabIndex === index) ? "active" : ""}`}
                          onClick={e => this.handleChangePath(e, `${match.url}#${tab}`)}>
                      {I18n.t(`key_DevicePageInfo_Tab${capitalizeFirstLetter(tab.replace(/_/gi, " "))}`)}
                    </span>
                  )
                }

              </ul>
            </div>
            <div className="col-9">
              <section className="tab-content">
                {
                  (!tabs[hash])
                    ? <DevicePartialOverview deviceID={device.id}/>
                    : tabs[hash]
                }
              </section>
            </div>
          </div>

        </div>

      </section>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    device: getDeviceByID(state, props.match.params.device),
    account: getAccount(state),
  }
};

export default withRouter(connect(mapStateToProps)(DevicePageInfo));

