import React from "react";
import PropTypes from 'prop-types'
import {I18n} from "../utils/i18n";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

class DeviceCardList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleOpen = (e, device) => {
    e.preventDefault();
    this.props.history.push(`/d/${device.id}`);
  };

  render() {
    const {devices} = this.props;

    return (
      <table className="table table-hover">
        <thead>
        <tr>
          <th scope="col" style={{width: "50px"}}>{I18n.t('key_DeviceCardList_TableHeaderStatus')}</th>
          <th scope="col" style={{minWidth: "300px"}}>{I18n.t('key_DeviceCardList_TableHeaderName')}</th>
          <th scope="col" style={{width: "100%"}}>{I18n.t('key_DeviceCardList_TableHeaderLocation')}</th>
          <th scope="col" style={{minWidth: "200px"}}>{I18n.t('key_DeviceCardList_TableHeaderUpdated')}</th>
        </tr>
        </thead>
        <tbody>

        {
          devices.sortDateDesc().map((item, index) => {
            return (
              <tr key={index} className="cursor-pointer" onClick={e => this.handleOpen(e, item)}>
                <td style={{verticalAlign: "middle"}}>
                    <span className={`badge ${!!item.status.online ? "badge-success" : "badge-primary"}`}>
                      {item.status.online ? "online" : "offline"}
                    </span>
                </td>
                <td style={{verticalAlign: "middle", maxWidth: "300px"}}
                    className="text-truncate">
                  <div>{item.meta.name}</div>
                  <div><small className="text-muted">serial:</small> <small>{item.meta.serial}</small></div>
                </td>
                <td style={{verticalAlign: "middle"}}>
                  <div>{item.meta.location}</div>
                  <div style={{fontSize: "12px"}}>{item.spec.address}</div>
                </td>
                <td style={{verticalAlign: "middle"}}>
                  {new Date(item.meta.updated).toLocaleString("ru-RU", {
                    hour12: false,
                    year: "numeric",
                    month: "2-digit",
                    day: "numeric",
                    hour: '2-digit',
                    minute: '2-digit'
                  })}
                </td>
              </tr>
            )
          })
        }

        </tbody>
      </table>
    );
  }
}

DeviceCardList.defaultProps = {
  devices: {},
};

DeviceCardList.propTypes = {
  devices: PropTypes.object,
};

const mapStateToProps = (state, props) => props;

export default withRouter(connect(mapStateToProps)(DeviceCardList));