import {requestJSON} from "../utils/request";
import {serialize} from "../utils/helpers";

const api_host = window._env_.API_HOST;

class Account {

  static Fetch() {
    let uri = [api_host, "partner"].join("/");
    return requestJSON("GET", uri, null, true);
  }

  static Create(body, opts) {
    let uri = [api_host, "partner"].join("/");
    opts = opts || {};
    if (Object.keys(opts).length) {
      let qs = serialize(opts)
      uri += !!qs.length ? "?" + qs : "";
    }
    return requestJSON("POST", uri, body);
  }

  static ProfileFetch() {
    let uri = [api_host, "partner", "profile"].join("/");
    return requestJSON("GET", uri, null, true);
  }

  static ProfileUpdate(data) {
    let uri = [api_host, "partner", "profile"].join("/");
    return requestJSON("PUT", uri, data, true);
  }

  static PasswordUpdate(body) {
    let uri = [api_host, "partner", "password"].join("/");
    return requestJSON("PUT", uri, body, true);
  }

  static PasswordChange(token, password) {
    let uri = [api_host, "partner", "password", "recovery", token].join("/");
    let body = {password: password};
    return requestJSON("PUT", uri, body, false);
  }

  static PasswordRecovery(login) {
    let uri = [api_host, "partner", "password", "recovery"].join("/") + "?login=" + login;
    return requestJSON("POST", uri, null, false);
  }

}

export default Account
