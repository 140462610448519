import {
  DEVICE_CREATE_FAILURE,
  DEVICE_CREATE_SUCCESS,
  DEVICE_FETCH_FAILURE,
  DEVICE_FETCH_SUCCESS,
  DEVICE_LIST_FETCH_FAILURE,
  DEVICE_LIST_FETCH_SUCCESS,
  DEVICE_UPDATE_FAILURE,
  DEVICE_UPDATE_SUCCESS,
  DEVICE_REMOVE_FAILURE,
  DEVICE_REMOVE_SUCCESS,
  DEVICE_SCHEDULE_FETCH_FAILURE,
  DEVICE_SCHEDULE_FETCH_SUCCESS,
  DEVICE_SCHEDULE_UPDATE_FAILURE,
  DEVICE_SCHEDULE_UPDATE_SUCCESS,
} from "../constants";


export const deviceReducer = {
  [DEVICE_CREATE_SUCCESS]: (state, payload) => {
    return state.mergeDeep(payload)
  },
  [DEVICE_CREATE_FAILURE]: (state) => {
    return state;
  },
  [DEVICE_FETCH_SUCCESS]: (state, payload) => {
    return state.mergeDeep(payload)
  },
  [DEVICE_FETCH_FAILURE]: (state) => {
    return state;
  },
  [DEVICE_LIST_FETCH_SUCCESS]: (state, payload) => {
    let newState = state.removeIn(["entities", "devices"]);
    return newState.mergeDeep(payload)
  },
  [DEVICE_LIST_FETCH_FAILURE]: (state) => {
    return state;
  },
  [DEVICE_UPDATE_SUCCESS]: (state, payload) => {
    return state.mergeDeep(payload)
  },
  [DEVICE_UPDATE_FAILURE]: (state) => {
    return state;
  },
  [DEVICE_REMOVE_SUCCESS]: (state, payload) => {
    return state.removeIn(["entities", "devices", payload.id]);
  },
  [DEVICE_REMOVE_FAILURE]: (state) => {
    return state;
  },

  [DEVICE_SCHEDULE_FETCH_SUCCESS]: (state, payload) => {
    return state.mergeDeep(payload)
  },
  [DEVICE_SCHEDULE_FETCH_FAILURE]: (state) => {
    return state;
  },
  [DEVICE_SCHEDULE_UPDATE_SUCCESS]: (state, payload) => {
    return state.mergeDeep(payload)
  },
  [DEVICE_SCHEDULE_UPDATE_FAILURE]: (state) => {
    return state;
  },

};
