import React from "react"
import PropTypes from "prop-types"
import Validator from "../utils/validator"
import {Button} from "../containers";
import {I18n} from "../utils/i18n";
import FileUploader from "./FileUploader";

class DeviceFormUpdateSSL extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      data: {
        tls: props.device.status.tls || false,
        ssl: {
          ca: "",
          cert: "",
          key: "",
        },
      },
      error: {
        tls: "",
        ca: "",
        cert: "",
        key: "",
        form: ""
      },
      pending: false,
      showUploader: false,
    };

  }

  handleSendEvent = () => {
    if (typeof this.props.onChange !== "function") return;

    const name = this.props.name || "device";
    let event = new Event(name);

    Object.defineProperty(event, 'target', {
      writable: false,
      value: {name: name, value: this.state.data},
    });

    this.props.onChange(event)
  };

  handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let error = "";

    if (name === "serial") error = Validator.NameField(value);

    let patch = {};
    switch (name) {
      case "tls":
        patch = {data: {...this.state.data, [name]: value}};
        break;
      case "ca":
      case"cert":
      case "key":
        patch = {data: {...this.state.data, ssl: {...this.state.data.ssl, [name]: value}}};
        break;
      default:
    }

    this.setState({
      ...patch,
      error: {...this.state.error, [name]: error},
      pending: false,
      success: false
    }, this.handleSendEvent)
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({pending: true});
    let data = this.state.data;

    return Promise.resolve(this.props.submit(data.tls, data.ssl))
      .then(this.handleSuccess, this.handleError)
  };

  handleSuccess = (res) => {
    this.setState({pending: false});
  };

  handleError = (errors) => {
    let e = this.state.error;
    if (errors.status === "Bad Request") e.form = errors.message;
    if (errors.message === "Bad tsl parameter") e.tls = errors.message;
    if (errors.message === "Bad ca parameter") e.ca = errors.message;
    if (errors.message === "Bad cert parameter") e.cert = errors.message;
    if (errors.message === "Bad key parameter") e.key = errors.message;

    this.setState({error: e, pending: false});
  };

  handleDisabled = () => {
    return (
      this.state.pending
      || (!!this.state.data.tls && (!this.state.data.ssl.ca || !this.state.data.ssl.cert || !this.state.data.ssl.key))
    )
  };

  handleShowUploader = () => this.setState({showUploader: true})

  render() {

    return (
      <form>

        <div className={`form-group ${!!this.state.error.form ? "has-danger" : ""}`}>
          {this.state.error.form && <span className="badge badge-danger">{this.state.error.form}</span>}
        </div>

        <div className="form-group">
          <div className="form-check">
            <input className="form-check-input" id="enableTLS"
                   name="tls"
                   type="checkbox"
                   checked={this.state.data.tls}
                   onChange={this.handleChange}/>
            <label className="form-check-label" htmlFor="enableTLS">
              {I18n.t('key_DeviceFormUpdateSSL_LabelTlsConfiguration')}
            </label>
            {
              (!!this.props.device.status.tls && !this.state.showUploader) && (
                <span className="cursor-pointer ml-4 text-primary"
                      onClick={this.handleShowUploader}>
                  {I18n.t('key_DeviceFormUpdateSSL_UploadNewCert')}
                </span>
              )
            }
          </div>
          <div className="invalid-feedback">{this.state.error.tls}</div>
        </div>

        {
          (!this.props.device.status.tls || this.state.showUploader) && (
            <div className="form-group">

              <div className="form-group">
                <FileUploader title={I18n.t('key_DeviceFormUpdateSSL_TlsCATitle')}
                              name="ca"
                              disabled={!this.state.data.tls}
                              accept=".pem, .crt"
                              onChange={this.handleChange}/>
                <div className="invalid-feedback">{this.state.error.ca}</div>
              </div>

              <div className="form-group">
                <FileUploader title={I18n.t('key_DeviceFormUpdateSSL_TlsCertTitle')}
                              name="cert"
                              disabled={!this.state.data.tls}
                              accept=".pem, .crt"
                              onChange={this.handleChange}/>
                <div className="invalid-feedback">{this.state.error.cert}</div>
              </div>

              <div className="form-group">
                <FileUploader title={I18n.t('key_DeviceFormUpdateSSL_TlsKeyTitle')}
                              name="key"
                              disabled={!this.state.data.tls}
                              accept=".pem, .key"
                              onChange={this.handleChange}/>
              </div>
              <div className="invalid-feedback">{this.state.error.key}</div>
            </div>
          )
        }

        <div className="form-group">
          <div className="pt-4 d-flex justify-content-center">
            <Button type='submit'
                    className='btn btn-primary text-capitalize'
                    disabled={this.handleDisabled()}
                    submit={this.handleSubmit}
                    main={I18n.t('key_Apply')}/>
          </div>
        </div>

      </form>
    )
  }
}

DeviceFormUpdateSSL.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  submit: PropTypes.func,
  cancel: PropTypes.func
};

export default DeviceFormUpdateSSL;