import {createSelector} from 'reselect/lib';

const getTagMap = (state, opts) => {
  const items = state.data.getIn(['entities', 'tags']);
  if (!items) return null;

  return items;
};

export const getTags = createSelector(
  [getTagMap],
  (item) => !!item ? item.toJS() : {}
);