import {requestJSON} from "../utils/request";
import {serialize} from "../utils/helpers";

const api_host = window._env_.API_HOST;

class Device {

  static Create(data) {
    let uri = [api_host, "device"].join("/");
    return requestJSON("POST", uri, data, true);
  }

  static Fetch(device) {
    let uri = [api_host, "device", device].join("/");
    return requestJSON("GET", uri, null, true);
  }

  static List(opts) {
    let uri = [api_host, "device"].join("/");
    opts = opts || {};
    let qs = serialize({
      page: parseInt(opts.page, 10) || 1,
      limit: parseInt(opts.limit, 10) || 10,
    });
    uri += !!qs.length ? "?" + qs : "";
    return requestJSON("GET", uri, null, true);
  }

  static Update(device, data) {
    let uri = [api_host, "device", device].join("/");
    return requestJSON("PUT", uri, data, true);
  }

  static Remove(device) {
    let uri = [api_host, "device", device].join("/");
    return requestJSON("DELETE", uri, null, true);
  }

  static ScheduleFetch(device) {
    let uri = [api_host, "device", device, "schedule"].join("/");
    return requestJSON("GET", uri, null, true);
  }

  static ScheduleUpdate(device, data) {
    let uri = [api_host, "device", device, "schedule"].join("/");
    return requestJSON("PUT", uri, data, true);
  }

}

export default Device