import {MediaPlan} from "../../api";
import {MEDIA_PLAN_LIST_FETCH_FAILURE, MEDIA_PLAN_LIST_FETCH_SUCCESS} from '../../constants';
import {normalize} from "normalizr";
import mediaPlanSchema from "../../schemas/mediaplan";


const SuccessAction = (payload) => ({
  type: MEDIA_PLAN_LIST_FETCH_SUCCESS,
  payload: normalize(payload, [mediaPlanSchema])
});

const FailureAction = (payload) => ({
  type: MEDIA_PLAN_LIST_FETCH_FAILURE,
  payload
});

export default (opts) => (dispatch) => {
  return new Promise((resolve, reject) => {
    MediaPlan.List(opts)
      .then(response => {
        dispatch(SuccessAction(response.items));
        resolve(response);
      })
      .catch(error => {
        dispatch(FailureAction(error));
        reject(error);
      });
  });
};