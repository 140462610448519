import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types"
import {I18n} from "../utils/i18n";
import * as api from "../api";
import {Resource} from "../utils/helpers";
import {Pagination} from '../containers'
import {Preloader} from "../components";

const default_days = {
  "mo": "Monday",
  "tu": "Tuesday",
  "we": "Wednesday",
  "th": "Thursday",
  "fr": "Friday",
  "sa": "Saturday",
  "su": "Sunday",
};

class DeviceScheduleFormUpdate extends React.Component {
  constructor(props) {
    super(props);
    const data = props.data || {spec: {}};
    this.state = {
      mediaplans: {
        items: [],
        total: 0,
        page: 1,
        limit: 10,
        pages: 1,
        maxPages: 5,
      },
      data: {
        mo: data.spec.mo || null,
        tu: data.spec.tu || null,
        we: data.spec.we || null,
        th: data.spec.th || null,
        fr: data.spec.fr || null,
        sa: data.spec.sa || null,
        su: data.spec.su || null
      },
      error: {
        mediaplan: "",
        date: "",
        start_time: "",
        end_time: "",
        range_start_date: "",
        range_end_date: "",
        form: ""
      },
      day: null,
      showMediaPlanList: false,
      pending: false,
      mediaplan_pending: true
    };
  }

  componentDidMount() {
    if (!!this.props.data.spec.media_plan) {
      api.MediaPlan.Fetch(this.props.data.spec.media_plan)
        .then((res) => {
          this.setState({
            mediaplan: res,
            mediaplan_pending: false,
          })
        })
        .catch(() => {
        });
    } else {
      this.setState({
        mediaplan_pending: false,
      })
    }

    api.MediaPlan.List({
      page: this.state.mediaplans.page,
      limit: this.state.mediaplans.limit
    })
      .then((res) => {
        const pages = (res.total > this.state.mediaplans.limit) ? Math.ceil(res.total / this.state.mediaplans.limit) : 1;

        if (res.page > pages) return this.handleMediaPlanPageChange(pages);

        this.setState({
          mediaplans: {
            ...this.state.mediaplans,
            total: res.total,
            page: res.page,
            pages: pages,
            items: res.items || [],
          }
        })
      })
      .catch(() => {
      });
  }

  handleMediaPlanPageChange = (page) => {
    api.MediaPlan.List({
      page: page,
      limit: this.state.mediaplans.limit
    })
      .then((res) => {
        const pages = (res.total > this.state.mediaplans.limit) ? Math.ceil(res.total / this.state.mediaplans.limit) : 1;

        if (res.page > pages) return this.handleMediaPlanPageChange(pages);

        this.setState({
          mediaplans: {
            ...this.state.mediaplans,
            total: res.total,
            page: res.page,
            pages: pages,
            items: res.items || [],
          }
        })
      })
      .catch(() => {
      });
  };

  handleShowMediaPlanList = (e, day) => this.setState({showMediaPlanList: true, day: day, mediaplan: null});
  handleHideMediaPlanList = () => this.setState({showMediaPlanList: false, day: null});
  handleSelectMediaPlan = (e, item) => this.setState({
    mediaplan: {
      id: item.meta.id,
      name: item.meta.name,
      start_time: item.spec.start_time,
      end_time: item.spec.end_time,
    }
  });
  handleMediaPlanDetach = (e, day) => {
    let data = this.state.data;
    data[day] = null;
    this.setState({data: data}, this.handleSubmit);
  };
  handleMediaPlanAttach = () => {
    this.setState({
      data: {...this.state.data, [this.state.day]: this.state.mediaplan},
      showMediaPlanList: false,
      day: null,
      mediaplan: null,
    }, this.handleSubmit)
  };

  handleSubmit = () => {
    this.setState({pending: true});
    let data = {...this.state.data};
    let spec = {};
    Object.keys(data).forEach(key => {
      spec[key] = (!!data[key]) ? data[key] : null;
    });
    return Promise.resolve(this.props.submit(spec))
      .then(this.handleSuccess, this.handleError)
  };

  handleSuccess = () => this.setState({pending: false});

  handleError = (errors) => {
    let e = this.state.error;
    if (errors.status === "Bad Request") e.form = errors.message;
    this.setState({error: e, pending: false});
  };

  handleDisabled = () => {
    return (
      this.state.pending
    )
  };

  getContent = (item) => {
    if (!item) return <div>{I18n.t('key_DeviceScheduleFormUpdate_MediaPlanScheduleIsEmpty')}</div>;
    return (
      <div className="d-flex flex-row">
        <div>
          <span className="badge badge-secondary">{item.start_time.split(" ")[0]}</span>
          <span className="px-1">&mdash;</span>
          <span className="badge badge-secondary">{item.end_time.split(" ")[0]}</span>
        </div>
        <div className="px-2">
          <span className="d-inline-block text-truncate" style={{maxWidth: "400px"}}>{item.name}</span>
        </div>
      </div>
    )
  };

  render() {
    if (this.state.mediaplan_pending) return <Preloader/>;

    if (!!this.state.showMediaPlanList) {
      return (
        <div>
          <div>
            <div className="list-group list-group-flush">

              {
                (this.state.mediaplans.items).map((item, index) => {

                  return (
                    <div key={index}
                         className={`list-group-item d-flex justify-content-between align-items-center cursor-pointer ${!!this.state.mediaplan && this.state.mediaplan.id === item.meta.id ? "list-group-item-primary" : ""}`}
                         onClick={e => this.handleSelectMediaPlan(e, item)}>

                      <div style={{verticalAlign: "middle", maxWidth: "300px"}}
                           className="text-truncate">{item.meta.name}</div>

                      <div style={{verticalAlign: "middle"}}>
                        <span className="badge badge-info" style={{width: "60px"}}>
                          {Resource.MemoryBytesToHumanSize(item.meta.size, 2)}
                        </span>
                      </div>

                    </div>
                  )
                })
              }

            </div>

            <div className="mt-4">
              <Pagination maxPages={this.state.mediaplans.maxPages}
                          currentPage={this.state.mediaplans.page}
                          numPages={this.state.mediaplans.pages}
                          change={this.handleMediaPlanPageChange}/>
            </div>

          </div>

          <div className="clearfix mt-5">
            <div className="float-left">
              <button className="btn btn-secondary"
                      onClick={this.handleHideMediaPlanList}>{I18n.t('key_Cancel')}</button>
            </div>
            <div className="float-right">
              <button className="btn btn-primary"
                      disabled={!this.state.mediaplan}
                      onClick={this.handleMediaPlanAttach}>{I18n.t('key_Add')}</button>
            </div>
          </div>

        </div>
      )
    }

    return (
      <div>

        <div className={`form-group ${!!this.state.error.form ? "has-danger" : ""}`}>
          {this.state.error.form && <span className="badge badge-danger">{this.state.error.form}</span>}
        </div>

        <div className="mb-5">
          <table className="table table-bordered">
            <thead>
            <tr>
              <th scope="col" className="text-center"
                  style={{width: "20px"}}>{I18n.t('key_DeviceScheduleFormUpdate_DayTitle')}</th>
              <th scope="col" className="text-center">{I18n.t('key_DeviceScheduleFormUpdate_MediaPlanTitle')}</th>
              <th scope="col" className="text-center"
                  style={{width: "100px"}}>{I18n.t('key_DeviceScheduleFormUpdate_ActionTitle')}</th>
            </tr>
            </thead>

            <tbody>
            {
              ["mo", "tu", "we", "th", "fr", "sa", "su"].map((day, index) => (
                <tr key={index}>
                  <th scope="row" className="text-center" style={{verticalAlign: "middle"}}>
                    {I18n.t(`key_${default_days[day]}Short`)}
                  </th>
                  <td style={{verticalAlign: "middle"}}>{this.getContent(this.state.data[day])}</td>
                  <td style={{verticalAlign: "middle"}}>
                    <div className="text-center">
                      {
                        (!!this.state.data[day])
                          ? <button className="btn btn-danger btn-sm"
                                    onClick={e => this.handleMediaPlanDetach(e, day)}>{I18n.t('key_Remove')}</button>
                          : <button className="btn btn-primary btn-sm"
                                    onClick={e => this.handleShowMediaPlanList(e, day)}>{I18n.t('key_Add')}</button>
                      }
                    </div>
                  </td>
                </tr>
              ))
            }
            </tbody>
          </table>
        </div>

      </div>
    );
  }
}

DeviceScheduleFormUpdate.defaultProps = {
  data: {},
};

DeviceScheduleFormUpdate.propTypes = {
  data: PropTypes.object,
  submit: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => props;

export default connect(mapStateToProps)(DeviceScheduleFormUpdate);
