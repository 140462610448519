export default {
  "key_ComingSoon": "Скоро будет..",

  "key_Help": "Помощь",
  "key_TermsAndConditions": "Условия использования",
  "key_PrivacyPolicy": "Политика конфиденциальности",

  // -- Preloader
  "key_Preloader_PleaseWait": "Пожалуйста, подождите немного, пока мы готовим контент!",

  // -- Buttons
  "key_Update": "Обновить",
  "key_Remove": "Удалить",
  "key_Copy": "Копировать",
  "key_Create": "Создать",
  "key_Cancel": "Отменить",
  "key_Add": "Добавить",
  "key_Apply": "Применить",
  "key_Close": "Закрыть",
  "key_Select": "Выбрать",
  "key_Change": "Изменить",
  "key_Confirm": "Подтвердить",
  "key_Back": "Назад",
  "key_Connect": "Подключить",
  "key_Disconnect": "Отключить",
  "key_SignIn": "Войти в систему",
  "key_SignUp": "Создать аккаунт",
  "key_Send": "Отправить",
  "key_Login": "Авторизоваться",
  "key_LogOut": "Выйти",
  "key_Recovery": "Восстановить",
  "key_Upload": "Загрузить",
  "key_Clear": "Очистить",
  "key_Search": "Поиск",

  "key_Home": "Главная",
  "key_Devices": "Устройства",
  "key_Schedules": "Расписание",
  "key_Profile": "Профайл",
  "key_Media": "Медиа",
  "key_Dashboard": "Информационная панель",
  "key_MediaPlans": "Медиа план",

  "key_MondayShort": "пн",
  "key_TuesdayShort": "вт",
  "key_WednesdayShort": "ср",
  "key_ThursdayShort": "чт",
  "key_FridayShort": "пт",
  "key_SaturdayShort": "сб",
  "key_SundayShort": "вс",

  // Errors
  "key_errorMsg_FORBIDDEN": "Доступ запрещен",
  "key_errorMsg_INVALID_INCOMING_JSON": "Неверные входящие данные",
  "key_errorMsg_BadRequest": "Плохой запрос",
  "key_errorMsg_BadEmailParameter": "Неверный параметр email",
  "key_errorMsg_BadUsernameParameter": "Неверный параметр username",
  "key_errorMsg_BadNameParameter": "Неверный параметр имени",
  "key_errorMsg_BadPasswordParameter": "Неверный параметр пароля",
  "key_errorMsg_BadInviteCodeParameter": "Пригласительный код невалиден",
  "key_errorMsg_USERNAME_CANNOT_BE_BLANK": "Имя пользователя не может быть пустым",
  "key_errorMsg_USERNAME_OR_EMAIL_INVALID": "Invalid username or email",
  "key_errorMsg_USERNAME_EXISTS": "Неверное имя пользователя или адрес электронной почты",
  "key_errorMsg_USERNAME_FORMAT_INVALID": "Имя пользователя может содержать только буквенно-цифровые символы или подчеркивание и не может начинаться или заканчиваться подчеркиванием",
  "key_errorMsg_USERNAME_TO_SHORT": "Имя пользователя слишком короткое (минимум 4 символа)",
  "key_errorMsg_USERNAME_TO_LONG": "Имя пользователя слишком длинное (максимум 64 символа)",
  "key_errorMsg_EMAIL_EXISTS": "Адрес электронной почты уже существует",
  "key_errorMsg_EMAIL_DOES_NOT_EXISTS": "Электронная почта не существует",
  "key_errorMsg_EMAIL_CANNOT_BE_BLANK": "Поле электронной почты не может быть пустым",
  "key_errorMsg_EMAIL_FORMAT_INVALID": "Неверный формат электронной почты",
  "key_errorMsg_PASSWORD_CANNOT_BE_BLANK": "Пароль не может быть пустым",
  "key_errorMsg_PASSWORD_FORMAT_INVALID": "Неверный формат пароля",
  "key_errorMsg_PASSWORDS_ARE_EQUAL": "Новый и текущий пароль совпадают",
  "key_errorMsg_PASSWORDS_NOT_EQUAL": "Пароли не совпадают",
  "key_errorMsg_PASSWORD_TO_SHORT": "Пароль слишком короткий (минимум 8 символов)",
  "key_errorMsg_PASSWORD_NOT_VALID": "Неверный пароль",
  "key_errorMsg_NAME_CANNOT_BE_BLANK": "Имя не может быть пустым",
  "key_errorMsg_NAME_FORMAT_INVALID": "Имя может содержать только буквенно-цифровые символы или одиночные дефисы или подчеркивания и не может начинаться или заканчиваться дефисом или подчеркиванием",
  "key_errorMsg_NAME_TO_SHORT": "Имя слишком короткое (минимум 4 символа)",
  "key_errorMsg_NAME_TO_LONG": "Имя слишком длинное (максимум 64 символа)",
  "key_errorMsg_INVALID_REDIRECT_URI": "Неверный редирект URI",
  "key_errorMsg_INVALID_FIELD_VALUE": "Неверное значение поля",
  "key_errorMsg_FIELD_CANNOT_BE_BLANK": "Поле не может быть пустым",
  "key_errorMsg_INTERNAL_SERVER_ERROR": "Внутренняя ошибка сервера",
  "key_errorMsg_UNDEFINED": "Неизвестная ошибка",
  "key_errorMsg_LOGIN_FAILED": "Неправильный логин",
  "key_errorMsg_PASSWORD_FAILED": "Неверный пароль",
  "key_errorMsg_SIGNIN_FAILED": "Неверный логин или пароль",
  "key_errorMsg_SIGNUP_FAILED": "Неверный логин или адрес электронной почты или пароль",
  "key_errorMsg_UNAUTHORIZED": "Вы должны быть зарегистрированы",
  "key_errorMsg_BAD_REQUEST": "Ваш браузер отправил запрос, который этот сервер не может понять",
  "key_errorMsg_NOT_FOUND": "Не найдено",
  "key_errorMsg_NOT_IMPLEMENTED": "Не реализована",
  "key_errorMsg_NOT_ACCEPTABLE": "Неверный параметр",
  "key_errorMsg_BAD_GATEWAY": "Плохой шлюз",
  "key_errorMsg_FIELD_TOTAL_LESS_FIELD_DAILY": "Общее количество не может быть меньше повседневного",
  "key_errorMsg_INCORRECT_DATE_OR_TIME": "Неверная дата или время",

  // -- AccountPartialSignIn
  "key_AccountPartialSignIn_Description": "Платформа управления партнерами ADS",

  // -- AccountFormSignIn
  "key_AccountFormSignIn_LabelLogin": "Почтовый адрес",
  "key_AccountFormSignIn_PlaceholderLogin": "Почтовый адрес",
  "key_AccountFormSignIn_LabelPassword": "Пароль",
  "key_AccountFormSignIn_PlaceholderPassword": "Пароль",
  "key_AccountFormSignIn_ForgotPassword": "Забыли пароль?",
  "key_AccountFormSignIn_CreateAccount": "Создать аккаунт",

  // -- AccountFormSignUp
  "key_AccountFormSignUp_LabelUsername": "Логин",
  "key_AccountFormSignUp_PlaceholderUsername": "Логин",
  "key_AccountFormSignUp_LabelEmail": "Почтовый адрес",
  "key_AccountFormSignUp_PlaceholderEmail": "Почтовый адрес",
  "key_AccountFormSignUp_LabelPassword": "Пароль",
  "key_AccountFormSignUp_PlaceholderPassword": "Пароль",
  "key_AccountFormSignUp_ButtonCreateAccount": "Создать аккаунт",
  "key_AccountFormSignUp_SigningUpOrSigningUp": "Регистрация означает, что вы прочитали и согласны с Условиями обслуживания и нашей Политикой конфиденциальности.",

  // -- AccountPartialSignUp
  "key_AccountFormSignUp_Description": "ADS partner management platform",
  "key_AccountFormSignUp_IHaveAccount": "Я уже имею аккаунт",

  // -- AccountPartialSignUp
  "key_AccountPartialSignUp_Description": "Платформа управления партнерами ADS",

  // -- Header
  "key_Header_TabItemDashboardMenu": "Главная",
  "key_Header_TabItemDevicesMenu": "Устройства",
  "key_Header_TabItemMediaMenu": "Медиа",
  "key_Header_TabItemScheduleMenu": "Медиаплан",
  "key_Header_Dropdown_Settings": "Настройки",

  // -- AccountHeader
  "key_AccountHeader_ManageAccount": " Упраавление аккаунтом",
  "key_AccountHeader_TabProfile": "Аккаунт",

  // -- AccountPartialGeneral
  "key_AccountPartialGeneral_Profile": "Профайл",
  "key_AccountPartialGeneral_YorEmailIsIdentity": "Ваш электронный адрес является вашей личностью и используется для входа.",
  "key_AccountPartialGeneral_Password": "Пароль",
  "key_AccountPartialGeneral_ChangingPassword": "Изменение пароля также приведет к сбросу ключа API.",
  "key_AccountPartialGeneral_Language": "Язык",
  "key_AccountPartialGeneral_ChangeLanguage": "Изменение языка",

  // -- AccountFormProfile
  "key_AccountFormProfile_LabelEmailAddress": "Адрес электронной почты",
  "key_AccountFormProfile_PlaceholderEmailAddress": "Адрес электронной почты",
  "key_AccountFormProfile_LabelName": "Имя",
  "key_AccountFormProfile_PlaceholderName": "Имя",
  "key_AccountFormProfile_LabelBio": "О вас",
  "key_AccountFormProfile_PlaceholderBio": "О вас",

  // -- AccountFormPasswordChange
  "key_AccountFormPasswordChange_LabelCurrentPassword": "Текущий пароль",
  "key_AccountFormPasswordChange_PlaceholderCurrentPassword": "Текущий пароль",
  "key_AccountFormPasswordChange_LabelEnterNewPassword": "Введите новый пароль",
  "key_AccountFormPasswordChange_PlaceholderEnterNewPassword": "Введите новый пароль",
  "key_AccountFormPasswordChange_LabelConfirmPassword": "Повторный ввод пароля",
  "key_AccountFormPasswordChange_PlaceholderConfirmPassword": "Повторный ввод пароля",

  // -- AccountPartialRecovery_RecoveryPassword
  "AccountPartialRecovery_RecoveryPassword": "Восстановление пароля",

  // -- AccountFormPasswordRecovery
  "key_AccountFormPasswordRecovery_LabelEmail": "Электронная почта",
  "key_AccountFormPasswordRecovery_PlaceholderEmail": "Электронная почта",
  "key_AccountFormPasswordRecovery_SendRecoveryEmailButton": "Отправить письмо для восстановления",
  "key_AccountFormPasswordRecovery_LabelConfirmPassword": "Подтвердите новый пароль",
  "key_AccountFormPasswordRecovery_PlaceholderConfirmPassword": "Подтвердите новый пароль",
  "key_AccountFormPasswordRecovery_LabelEnterNewPassword": "Введите новый пароль",
  "key_AccountFormPasswordRecovery_PlaceholderEnterNewPassword": "Введите новый пароль",

  // -- PageRecovery
  "key_PageRecovery_PasswordRecovery": "Восстановление пароля",
  "key_PageRecovery_MessageSent": "На вашу почту было отправлено сообщение",
  "key_PageRecovery_ReturnToSignIn": "вернуться на страницу входа",
  "key_PageRecovery_BackToLogin": "Вернуться на страницу авторизации",

  // -- PageNotFound
  "key_PageNotFound_404PageNotFound": "404 страница не найдена",
  "key_PageNotFound_WeAreSorry": "К сожалению, страница, которую вы ищете, не существует.",
  "key_PageNotFound_GoToHOME": "ВЕРНУТЬСЯ ДОМОЙ",

  // -- PageProjectCreate
  "key_PageDeviceCreate_NewDeviceTitle": "Новое устройство",

  // -- DeviceFormCreate
  "key_DeviceFormCreate_LabelName": "Название устройства",
  "key_DeviceFormCreate_PlaceholderName": "Название устройства",
  "key_DeviceFormCreate_LabelSerialName": "Серийный номер устройства",
  "key_DeviceFormCreate_PlaceholderSerialName": "Серийный номер устройства",
  "key_DeviceFormCreate_LabelAddress": "Адрес устройства",
  "key_DeviceFormCreate_PlaceholderAddress": "Адрес устройства",
  "key_DeviceFormCreate_ButtonCreate": "Добаавить устройство",
  "key_DeviceFormCreate_LabelTlsConfiguration": " Включить TLS",
  "key_DeviceFormCreate_TlsCATitle": "CA сертификат (.crt, .pem)",
  "key_DeviceFormCreate_TlsCertTitle": "SSL сертификат (.crt, .pem)",
  "key_DeviceFormCreate_TlsKeyTitle": "SSL ключ (.key, .pem)",

  // -- DeviceFormUpdateGeneralInfo
  "key_DeviceFormUpdateGeneralInfo_LabelSerialNumber": " Серийный номер",
  "key_DeviceFormUpdateGeneralInfo_LabelName": " Название",
  "key_DeviceFormUpdateGeneralInfo_PlaceholderName": " Название",
  "key_DeviceFormUpdateGeneralInfo_LabelAddress": " Адрес",
  "key_DeviceFormUpdateGeneralInfo_PlaceholderAddress": " Адрес",

  // -- DeviceFormUpdateSSL
  "key_DeviceFormUpdateSSL_LabelTlsConfiguration": " Включить TLS",
  "key_DeviceFormUpdateSSL_TlsCATitle": "CA сертификат (.crt, .pem)",
  "key_DeviceFormUpdateSSL_TlsCertTitle": "SSL сертификат (.crt, .pem)",
  "key_DeviceFormUpdateSSL_TlsKeyTitle": "SSL ключ (.key, .pem)",
  "key_DeviceFormUpdateSSL_UploadNewCert": "Загрузить новые сертификаты?",

  // -- DevicePageList
  "key_DevicePageList_AddNewDevice": "Добавить новое устройство",

  // -- DeviceCardList
  "key_DeviceCardList_TableHeaderName": "Название",
  "key_DeviceCardList_TableHeaderSerialNumber": "Серийный номер",
  "key_DeviceCardList_TableHeaderLocation": "Место расположение",
  "key_DeviceCardList_TableHeaderStatus": "Статус",
  "key_DeviceCardList_TableHeaderUpdated": "Обновлялся",

  // -- DeviceFormRemove
  "key_DeviceFormRemove_DeviceSerialName": "Серийный номер устройства",
  "key_DeviceFormRemove_DeviceName": "Имя устройства",
  "key_DeviceFormRemove_PleaseType": "Пожалуйста, введите имя устройства для подтверждения.",

  // -- DevicePageInfo
  "key_DevicePageInfo_TabOverview": "Обзор",
  "key_DevicePageInfo_TabSchedule": "Расписание",
  "key_DevicePageInfo_TabSettings": "Настройки",

  // -- DevicePartialOverview
  "key_DevicePartialOverview_ScheduleTitle": "Расписание",
  "key_DevicePartialOverview_CommercialScheduleTitle": "Расписание коммерческого времени",

  // -- DevicePartialSettings
  "key_DevicePartialSettings_Title": "Настройки",
  "key_DevicePartialSettings_GeneralSettings": "Общие настройки",
  "key_DevicePartialSettings_SecuritySettings": "Настройки безопасности",
  "key_DevicePartialSettings_DeleteDevice": "Удалить устройство",
  "key_DevicePartialSettings_ThisActionCannotBeUndone": "Это действие не может быть отменено. Это навсегда удалит устройство '%{name}'.",

  // -- MediaFileCardList
  "key_MediaFileCardList_TableHeaderStatus": "Состояние",
  "key_MediaFileCardList_TableHeaderState": "Статус",
  "key_MediaFileCardList_TableHeaderName": "Название",
  "key_MediaFileCardList_TableHeaderSize": "Размер",
  "key_MediaFileCardList_TableHeaderUpdated": "Создан",

  "key_StatusMediaError": "Ошибка",
  "key_StatusMediaReady": "Готов",
  "key_StatusMediaUploading": "Загрузка",
  "key_StatusMediaPreparing": "Подготовка",
  "key_StatusMediaDeleting": "Удаление",
  "key_StatusMediaUnknown": "Неопределен",

  "key_StatusMediaCreated": "Новый",
  "key_StatusMediaAccepted": "Принят",
  "key_StatusMediaRejected": "Отклонен",

  // -- key_DevicePartialSchedule
  "key_DevicePartialSchedule_ScheduleTitle": "Расписание",
  "key_DevicePartialSchedule_ScheduleUpdateTitle": "Настройка расписания",

  // -- PageScheduleDeviceCreate
  "key_PageScheduleDeviceCreate_NewDeviceTitle": "Создание нового раасписание",

  // -- DeviceScheduleFormCreate
  "key_DeviceScheduleFormCreate_LabelName": "Название",
  "key_DeviceScheduleFormCreate_PlaceholderName": "Название",
  "key_DeviceScheduleFormCreate_GeneralTitle": "Информация",
  "key_DeviceScheduleFormCreate_MediaPlanTitle": "Медиаплан",
  "key_DeviceScheduleFormCreate_PlaybackSettingsTitle": "Настройка даты и времени воспроизведения контента",
  "key_DeviceScheduleFormCreate_LabelDateOrDates": "Дата/Даты",
  "key_DeviceScheduleFormCreate_LabelDateRange": "Период",
  "key_DeviceScheduleFormCreate_ContentTitle": "Название",
  "key_DeviceScheduleFormCreate_ContentSize": "Размер",
  "key_DeviceScheduleFormCreate_PlaceholderSelectDay": "Выберите день",
  "key_DeviceScheduleFormCreate_PlaceholderSelectStartTime": "Выберите время старта показа",
  "key_DeviceScheduleFormCreate_PlaceholderSelectEndTime": "Выберите время окончания показа",
  "key_DeviceScheduleFormCreate_PlaceholderSelectStartDay": "Выберите день старта показа",
  "key_DeviceScheduleFormCreate_PlaceholderSelectEndDay": "Выберите день окончания показа",
  "key_DeviceScheduleFormCreate_PlaceholderLimitTitle": "Настройка количества воспроизведений",
  "key_DeviceScheduleFormCreate_LabelEveryDay": "Каждый день",
  "key_DeviceScheduleFormCreate_LabelWorkDays": "Рабочие дни",
  "key_DeviceScheduleFormCreate_LabelWeekend": "Выходные дни",
  "key_DeviceScheduleFormCreate_LabelCustom": "Выбрать дни",
  "key_DeviceScheduleFormCreate_LabelMonday": "пн",
  "key_DeviceScheduleFormCreate_LabelTuesday": "вт",
  "key_DeviceScheduleFormCreate_LabelWednesday": "ср",
  "key_DeviceScheduleFormCreate_LabelThursday": "чт",
  "key_DeviceScheduleFormCreate_LabelFriday": "пт",
  "key_DeviceScheduleFormCreate_LabelSaturday": "сб",
  "key_DeviceScheduleFormCreate_LabelSunday": "вс",
  "key_DeviceScheduleFormCreate_LabelTotalLimit": "Всего показов",
  "key_DeviceScheduleFormCreate_PlaceholderTotalLimit": "Всего показов",
  "key_DeviceScheduleFormCreate_LabelDailyLimit": "Показов в день",
  "key_DeviceScheduleFormCreate_PlaceholderDailyLimit": "Показов в день",

  // -- DeviceScheduleFormUpdate
  "key_DeviceScheduleFormUpdate_LabelName": "Название",
  "key_DeviceScheduleFormUpdate_PlaceholderName": "Название",
  "key_DeviceScheduleFormUpdate_TimeInterval": "Временной интервал",
  "key_DeviceScheduleFormUpdate_GeneralTitle": "Информация",
  "key_DeviceScheduleFormUpdate_MediaPlanTitleTitle": "Медиаплан",
  "key_DeviceScheduleFormUpdate_PlaybackSettingsTitle": "Настройка даты и времени воспроизведения контента",
  "key_DeviceScheduleFormUpdate_LabelDateOrDates": "Дата/Даты",
  "key_DeviceScheduleFormUpdate_LabelDateRange": "Период",
  "key_DeviceScheduleFormUpdate_ContentTitle": "Название",
  "key_DeviceScheduleFormUpdate_ContentSize": "Размер",
  "key_DeviceScheduleFormUpdate_PlaceholderSelectDay": "Выберите день",
  "key_DeviceScheduleFormUpdate_PlaceholderSelectStartTime": "Выберите время старта показа",
  "key_DeviceScheduleFormUpdate_PlaceholderSelectEndTime": "Выберите время окончания показа",
  "key_DeviceScheduleFormUpdate_PlaceholderSelectStartDay": "Выберите день старта показа",
  "key_DeviceScheduleFormUpdate_PlaceholderSelectEndDay": "Выберите день окончания показа",
  "key_DeviceScheduleFormUpdate_PlaceholderLimitTitle": "Настройка количества воспроизведений",
  "key_DeviceScheduleFormUpdate_LabelEveryDay": "Каждый день",
  "key_DeviceScheduleFormUpdate_LabelWorkDays": "Рабочие дни",
  "key_DeviceScheduleFormUpdate_LabelWeekend": "Выходные дни",
  "key_DeviceScheduleFormUpdate_LabelCustom": "Выбрать дни",
  "key_DeviceScheduleFormUpdate_LabelMonday": "пн",
  "key_DeviceScheduleFormUpdate_LabelTuesday": "вт",
  "key_DeviceScheduleFormUpdate_LabelWednesday": "ср",
  "key_DeviceScheduleFormUpdate_LabelThursday": "чт",
  "key_DeviceScheduleFormUpdate_LabelFriday": "пт",
  "key_DeviceScheduleFormUpdate_LabelSaturday": "сб",
  "key_DeviceScheduleFormUpdate_LabelSunday": "вс",
  "key_DeviceScheduleFormUpdate_LabelTotalLimit": "Всего показов",
  "key_DeviceScheduleFormUpdate_PlaceholderTotalLimit": "Всего показов",
  "key_DeviceScheduleFormUpdate_LabelDailyLimit": "Показов в день",
  "key_DeviceScheduleFormUpdate_PlaceholderDailyLimit": "Показов в день",
  "key_DeviceScheduleFormUpdate_MediaPlanIsEmpty": "Список медиапланов пуст",
  "key_DeviceScheduleFormUpdate_MediaPlanCreateLink": "Добавить новый медиаплан",
  "key_DeviceScheduleFormUpdate_MediaPlanScheduleIsEmpty": "На этот день расписание не выбрано",
  "key_DeviceScheduleFormUpdate_DayTitle": " День",
  "key_DeviceScheduleFormUpdate_MediaPlanTitle": "Медиа план",
  "key_DeviceScheduleFormUpdate_ActionTitle": "Действие",

  // -- DeviceScheduleCardList
  "key_DeviceScheduleCardList_TableHeaderName": "Название",
  "key_DeviceScheduleCardList_TableHeaderUpdated": "Обновлен",

  // -- PageCommercialScheduleDeviceCreate
  "key_PageCommercialScheduleDeviceCreate_NewDeviceTitle": "Создание нового раасписание",

  // -- MediaPlanFormClone
  "key_MediaPlanFormClone_Duration": "Продолжительность (hh:mm)",
  "key_MediaPlanFormClone_DefaultTitle": " По умолчанию",
  "key_MediaPlanFormClone_CommercialTimeTitle": "коммерческое время",
  "key_MediaPlanFormClone_ExternalSourceTimeTitle": "внешний источник",
  "key_MediaPlanFormClone_Tags": "Теги",
  "key_MediaPlanFormClone_InfoMessage": "Для текущего интервала будет добавлено: %{count} шт.",
  "key_MediaPlanFormClone_WarningMessage": "Установленный интервал должен быть больше длины первого контента",

  // -- DeviceCommercialScheduleFormCreate
  "key_DeviceCommercialScheduleFormCreate_LabelName": "Название",
  "key_DeviceCommercialScheduleFormCreate_PlaceholderName": "Название",
  "key_DeviceCommercialScheduleFormCreate_GeneralTitle": "Информация",
  "key_DeviceCommercialScheduleFormCreate_TagsTitle": "Теги",
  "key_DeviceCommercialScheduleFormCreate_Duration": "Продолжительность (hh:mm)",
  "key_DeviceCommercialScheduleFormCreate_PlaybackSettingsTitle": "Настройка даты и времени воспроизведения контента",
  "key_DeviceCommercialScheduleFormCreate_LabelDateOrDates": "Дата/Даты",
  "key_DeviceCommercialScheduleFormCreate_LabelDateRange": "Период",
  "key_DeviceCommercialScheduleFormCreate_PlaceholderSelectDay": "Выберите день",
  "key_DeviceCommercialScheduleFormCreate_PlaceholderSelectStartTime": "Выберите время старта показа",
  "key_DeviceCommercialScheduleFormCreate_PlaceholderSelectEndTime": "Выберите время окончания показа",
  "key_DeviceCommercialScheduleFormCreate_PlaceholderSelectStartDay": "Выберите день старта показа",
  "key_DeviceCommercialScheduleFormCreate_PlaceholderSelectEndDay": "Выберите день окончания показа",
  "key_DeviceCommercialScheduleFormCreate_PlaceholderLimitTitle": "Настройка количества воспроизведений",
  "key_DeviceCommercialScheduleFormCreate_LabelEveryDay": "Каждый день",
  "key_DeviceCommercialScheduleFormCreate_LabelWorkDays": "Рабочие дни",
  "key_DeviceCommercialScheduleFormCreate_LabelWeekend": "Выходные дни",
  "key_DeviceCommercialScheduleFormCreate_LabelCustom": "Выбрать дни",
  "key_DeviceCommercialScheduleFormCreate_LabelMonday": "пн",
  "key_DeviceCommercialScheduleFormCreate_LabelTuesday": "вт",
  "key_DeviceCommercialScheduleFormCreate_LabelWednesday": "ср",
  "key_DeviceCommercialScheduleFormCreate_LabelThursday": "чт",
  "key_DeviceCommercialScheduleFormCreate_LabelFriday": "пт",
  "key_DeviceCommercialScheduleFormCreate_LabelSaturday": "сб",
  "key_DeviceCommercialScheduleFormCreate_LabelSunday": "вс",
  "key_DeviceCommercialScheduleFormCreate_LabelTotalLimit": "Всего показов",
  "key_DeviceCommercialScheduleFormCreate_PlaceholderTotalLimit": "Всего показов",
  "key_DeviceCommercialScheduleFormCreate_LabelDailyLimit": "Показов в день",
  "key_DeviceCommercialScheduleFormCreate_PlaceholderDailyLimit": "Показов в день",

  // -- DeviceCommercialScheduleFormUpdate
  "key_DeviceCommercialScheduleFormUpdate_LabelName": "Название",
  "key_DeviceCommercialScheduleFormUpdate_PlaceholderName": "Название",
  "key_DeviceCommercialScheduleFormUpdate_GeneralTitle": "Информация",
  "key_DeviceCommercialScheduleFormUpdate_TagsTitle": "Теги",
  "key_DeviceCommercialScheduleFormUpdate_PlaybackSettingsTitle": "Настройка даты и времени воспроизведения контента",
  "key_DeviceCommercialScheduleFormUpdate_LabelDateOrDates": "Дата/Даты",
  "key_DeviceCommercialScheduleFormUpdate_LabelDateRange": "Период",
  "key_DeviceCommercialScheduleFormUpdate_PlaceholderSelectDay": "Выберите день",
  "key_DeviceCommercialScheduleFormUpdate_PlaceholderSelectStartTime": "Выберите время старта показа",
  "key_DeviceCommercialScheduleFormUpdate_PlaceholderSelectEndTime": "Выберите время окончания показа",
  "key_DeviceCommercialScheduleFormUpdate_PlaceholderSelectStartDay": "Выберите день старта показа",
  "key_DeviceCommercialScheduleFormUpdate_PlaceholderSelectEndDay": "Выберите день окончания показа",
  "key_DeviceCommercialScheduleFormUpdate_PlaceholderLimitTitle": "Настройка количества воспроизведений",
  "key_DeviceCommercialScheduleFormUpdate_LabelEveryDay": "Каждый день",
  "key_DeviceCommercialScheduleFormUpdate_LabelWorkDays": "Рабочие дни",
  "key_DeviceCommercialScheduleFormUpdate_LabelWeekend": "Выходные дни",
  "key_DeviceCommercialScheduleFormUpdate_LabelCustom": "Выбрать дни",
  "key_DeviceCommercialScheduleFormUpdate_LabelMonday": "пн",
  "key_DeviceCommercialScheduleFormUpdate_LabelTuesday": "вт",
  "key_DeviceCommercialScheduleFormUpdate_LabelWednesday": "ср",
  "key_DeviceCommercialScheduleFormUpdate_LabelThursday": "чт",
  "key_DeviceCommercialScheduleFormUpdate_LabelFriday": "пт",
  "key_DeviceCommercialScheduleFormUpdate_LabelSaturday": "сб",
  "key_DeviceCommercialScheduleFormUpdate_LabelSunday": "вс",
  "key_DeviceCommercialScheduleFormUpdate_LabelTotalLimit": "Всего показов",
  "key_DeviceCommercialScheduleFormUpdate_PlaceholderTotalLimit": "Всего показов",
  "key_DeviceCommercialScheduleFormUpdate_LabelDailyLimit": "Показов в день",
  "key_DeviceCommercialScheduleFormUpdate_PlaceholderDailyLimit": "Показов в день",

  // -- DeviceCommercialScheduleCardList
  "key_DeviceCommercialScheduleCardList_TableHeaderName": "Название",
  "key_DeviceCommercialScheduleCardList_TableHeaderUpdated": "Обновлен",

  // -- MultiFileUploader
  "MultiFileUploader_DragAndDropDescription": "Перетащите сюда несколько файлов или нажмите, чтобы выбрать файлы",

  // -- PageMediaPlanCreate
  "key_PageMediaPlanCreate_NewMediaPlanTitle": "Создать новый медиа план",

  // -- MediaPlanPageList
  "key_MediaPlanPageList_AddNewMediaPlan": "Добавить новый медиа план",

  // -- MediaPlanFormCreate
  "key_MediaPlanFormCreate_LabelName": "Название",
  "key_MediaPlanFormCreate_PlaceholderName": "Название",

  // -- MediaPlanCardList
  "key_MediaPlanCardList_TableHeaderName": "Название",
  "key_MediaPlanCardList_TableHeaderUpdated": "Обновлялся",

  // -- MediaPlanFormUpdate
  "key_MediaPlanFormUpdate_MediaFileTitle": "Медиа файлы",
  "key_MediaPlanFormUpdate_MediaFileFilterOnlyVideo": "Видео",
  "key_MediaPlanFormUpdate_MediaFileFilterOnlyStatic": "Статика",
  "key_MediaPlanFormUpdate_CommercialTitle": "Комерческое время",
  "key_MediaPlanFormUpdate_ExternalSourceTitle": "Внешний источник",
  "key_MediaPlanFormUpdate_GeneralInfo": "Основная информация",
  "key_MediaPlanFormUpdate_CommercialInfo": "Коммерческое время",
  "key_MediaPlanFormUpdate_ExternalSourceInfo": "Внешний источник",
  "key_MediaPlanFormUpdate_CloneContent": "Копирование контента",
  "key_MediaPlanFormUpdate_ScheduleListEmpty": "Список расписаниея пуст",
  "key_MediaPlanFormUpdate_Tags": "Теги",
  "key_MediaPlanFormUpdate_CommercialTimeTitle": "коммерческое время",
  "key_MediaPlanFormUpdate_ExternalSourceTimeTitle": "внешний источник",
  "key_MediaPlanFormUpdate_StaticModalHeaderSettings": "Укажите хронометраж стаатичного контента (hh:mm)",
  "key_MediaPlanFormUpdate_DefaultTitle": " По умолчанию",
  "key_MediaPlanFormUpdate_RemoveConfirm": "Вы уверены, что хотите удалить `%{name}` медиаплан?",
  "key_MediaPlanFormUpdate_CopyBlocks": "Копировать: (%{count})",
  "key_MediaPlanFormUpdate_ClearBlocks": "Очистить плейлист",

  // -- MediaPageList
  "key_MediaPageList_MediaRemoveFileConfirm": "Вы уверены, что хотите удалить `%{file}` файл?",
  "key_MediaPageList_UseInMediaPlanList": "Данный файл используется в медиапланах",
  "key_MediaPageList_NotUseInMediaPlanList": "Данный файл не используется в медиапланах",
};
