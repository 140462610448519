import React from 'react';
import PropTypes from "prop-types"
import {I18n} from "../utils/i18n";
import {TimePicker} from "antd";
import {parseTime} from "../utils/helpers";
import {TagTreeCard} from '../containers'

import moment from "moment";

import "antd/dist/antd.css";

const timeFormat = 'HH:mm';

const list_to_tree = (list) => {
  let map = {}, node, roots = [], i;

  let items = list.map(item => {
    return {
      title: item.name,
      key: item.id,
      parentId: item.parent_id,
    }
  });

  for (i = 0; i < items.length; i++) {
    map[items[i].key] = i; // initialize the map
    items[i].children = []; // initialize the children
  }

  for (i = 0; i < items.length; i++) {
    node = items[i];
    if (node.parentId !== null) {
      node.checkable = true
      node.selectable = true
      items[map[node.parentId]].children.push(node);
    } else {
      roots.push(node)
    }
  }

  for (i = 0; i < roots.length; i++) {
    roots[i].checkable = !roots[i].children;
    roots[i].selectable = !roots[i].children;
  }

  return roots;
}

class MediaPlanFormCommercialFormChange extends React.Component {

  constructor(props) {
    super(props);

    const data = props.data || {};

    let tags = [];
    (data.tags || []).forEach(item => {
      return tags.push(item)
    });

    this.state = {
      data: {
        id: data.id || null,
        tags: tags,
        duration: (!!data.duration) ? parseTime(data.duration) : parseTime("00:00:00"),
      },
      tags: list_to_tree(props.tags),
      error: {
        form: ""
      },
      pending: false
    };
  }

  handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    let value = target.value;
    this.setState({data: {...this.state.data, [name]: value}})
  };

  handleDateChange = (name, value) => {
    let date = !!value ? value.toDate() : null;
    this.setState({data: {...this.state.data, [name]: date}})
  };

  handleCancel = (e) => {
    this.props.onCancel()
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({pending: true});
    let data = {...this.state.data};

    data.duration = new Date(data.duration).toLocaleTimeString();

    this.props.onSubmit(data)
  };

  handleDisabled = () => {
    return (
      this.state.pending
      || !this.state.data.tags.length
    )
  };

  render() {
    return (
      <form>

        <div className={`form-group ${!!this.state.error.form ? "has-danger" : ""}`}>
          {this.state.error.form && <span className="badge badge-danger">{this.state.error.form}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="nameInput" className="col-form-label">{I18n.t('key_DeviceCommercialScheduleFormCreate_Duration')}</label>
          <div>
            <TimePicker format={timeFormat}
                        inputReadOnly={true}
                        allowClear={false}
                        value={moment(this.state.data.duration, timeFormat)}
                        defaultValue={moment('00:00', timeFormat)}
                        defaultOpenValue={moment('00:00', timeFormat)}
                        onChange={val => this.handleDateChange("duration", val)}/>
          </div>
        </div>

        {/* ================================================================================= */}
        {/* TAGS ============================================================================ */}
        {/* ================================================================================= */}
        <div className="form-group">
          <label htmlFor="nameInput"
                 className="col-form-label">{I18n.t('key_DeviceCommercialScheduleFormCreate_TagsTitle')}</label>
          <TagTreeCard name="tags"
                       data={this.state.tags}
                       selected={this.state.data.tags}
                       onChange={this.handleChange}/>
        </div>

        <div className="clearfix my-4">
          <div className="float-left">
            <button className="btn btn-secondary"
                    onClick={this.handleCancel}>
              {I18n.t('key_Cancel')}
            </button>
          </div>
          <div className="float-right">
            <button className="btn btn-primary"
                    onClick={this.handleSubmit}
                    disabled={this.handleDisabled()}>
              {I18n.t('key_Apply')}
            </button>
          </div>
        </div>

      </form>
    )
  }
}

MediaPlanFormCommercialFormChange.propTypes = {
  name: PropTypes.string,
  tags: PropTypes.array,
  limit: PropTypes.number,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default MediaPlanFormCommercialFormChange;