import {MediaPlan} from '../../api';

import {MEDIA_PLAN_REMOVE_FAILURE, MEDIA_PLAN_REMOVE_SUCCESS} from '../../constants';

const SuccessAction = (payload) => ({
  type: MEDIA_PLAN_REMOVE_SUCCESS,
  payload
});

const FailureAction = (payload) => ({
  type: MEDIA_PLAN_REMOVE_FAILURE,
  payload
});

export default (mediaplan) => (dispatch) => {
  return new Promise((resolve, reject) => {
    MediaPlan.Remove(mediaplan.id)
      .then(() => {
        dispatch(SuccessAction(mediaplan));
        resolve(mediaplan)
      })
      .catch(error => {
        dispatch(FailureAction(error));
        reject(error)
      })
  })
};