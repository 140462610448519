import React from "react";
import PropTypes from 'prop-types'
import {I18n} from "../utils/i18n";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

class MediaPlanCardList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleOpen = (e, mediaplan) => {
    e.preventDefault();
    this.props.history.push(`/mp/${mediaplan.id}`);
  };

  render() {
    const {mediaplans} = this.props;

    return (
      <table className="table table-hover">
        <thead>
        <tr>
          <th scope="col" style={{minWidth: "300px"}}>{I18n.t('key_MediaPlanCardList_TableHeaderName')}</th>
          <th scope="col" style={{minWidth: "200px"}}>{I18n.t('key_MediaPlanCardList_TableHeaderUpdated')}</th>
        </tr>
        </thead>
        <tbody>

        {
          mediaplans.sortDateAsc().map((item, index) => {
            return (
              <tr key={index} className="cursor-pointer" onClick={e => this.handleOpen(e, item)}>
                <td style={{verticalAlign: "middle", maxWidth: "300px"}}
                    className="text-truncate">
                  <div>{item.meta.name}</div>
                </td>
                <td style={{verticalAlign: "middle"}}>
                  {new Date(item.meta.updated).toLocaleString("ru-RU", {
                    hour12: false,
                    year: "numeric",
                    month: "2-digit",
                    day: "numeric",
                    hour: '2-digit',
                    minute: '2-digit'
                  })}
                </td>
              </tr>
            )
          })
        }

        </tbody>
      </table>
    );
  }
}

MediaPlanCardList.defaultProps = {
  mediaplans: {},
};

MediaPlanCardList.propTypes = {
  mediaplans: PropTypes.object,
};

const mapStateToProps = (state, props) => props;

export default withRouter(connect(mapStateToProps)(MediaPlanCardList));
