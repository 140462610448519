import {createSelector} from 'reselect/lib';

const getDeviceMap = (state, opts) => {
  const items = state.data.getIn(['entities', 'devices']);
  if (!items) return null;

  return (!!opts && !!opts.filter)
    ? items.filter(item => item.get("meta").get("name").indexOf(opts.filter) !== -1)
    : items;
};

const getDeviceItemByID = (state, id) => {
  const items = getDeviceMap(state);

  return !!items
    ? items.find(item => item.get('id') === id)
    : null
};

export const getDevices = createSelector(
  [getDeviceMap],
  (item) => patch.call(!!item ? item.toJS() : {})
);

export const getDeviceByID = createSelector(
  [getDeviceItemByID],
  (item) => !!item ? item.toJS() : null
);

function patch() {

  const sortableByDate = (obj, sort) => {
    // convert object into array
    let sortable = [];
    for (var key in obj)
      if (obj.hasOwnProperty(key)) sortable.push(obj[key]);

    // sort items by value
    sortable.sort(function (a, b) {
      let x = new Date(a.meta.created);
      let y = new Date(b.meta.created);

      return (!sort || sort.toLowerCase() === 'asc') ? y - x : x - y;
    });

    return sortable;
  };

  const sortDateAsc = sortableByDate(this, "asc");
  const sortDateDesc = sortableByDate(this, "desc");

  Object.defineProperty(this, 'sortDateAsc', {
    get: () => () => sortDateAsc || [],
    enumerable: false
  });

  Object.defineProperty(this, 'sortDateDesc', {
    get: () => () => sortDateDesc || [],
    enumerable: false
  });

  return this
}