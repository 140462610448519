import {requestJSON} from "../utils/request";

const api_host = window._env_.API_HOST;

class Session {

  static Create(login, password) {
    let uri = [api_host, "partner", "session"].join("/");
    let body = {login: login, password: password};
    return requestJSON("POST", uri, body, false);
  }

}


export default Session