import {schema} from 'normalizr';

const tagSchema = new schema.Entity('tags', {}, {
  idAttribute: data => data.id,
  processStrategy: (data) => {
    return data;
  }
});

export default tagSchema;
