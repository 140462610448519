import {Device} from '../../api';

import {DEVICE_CREATE_FAILURE, DEVICE_CREATE_SUCCESS} from '../../constants';
import {normalize} from "normalizr";
import deviceSchema from "../../schemas/device";


const SuccessAction = (payload) => ({
  type: DEVICE_CREATE_SUCCESS,
  payload: normalize([payload], [deviceSchema])
});

const FailureAction = (payload) => ({
  type: DEVICE_CREATE_FAILURE,
  payload
});

export default (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Device.Create(data)
      .then(response => {
        dispatch(SuccessAction(response));
        resolve(response);
      })
      .catch(error => {
        dispatch(FailureAction(error));
        reject(error);
      })
  })
};
