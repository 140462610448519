import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {getDeviceScheduleByDeviceID} from "../../selectors";
import PropTypes from "prop-types";
import {DeviceScheduleFormUpdate} from "./../../containers";
import {I18n} from "../../utils/i18n";
import {Device} from "../../actions";

class DevicePartialSchedule extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  handleSubmitUpdateSchedule = (data) => {
    return this.props.dispatch(Device.Schedule.Update(this.props.deviceID, data))
  };

  render() {
    const {schedule} = this.props;

    return (
      <div>
        <div className="mb-4 border-bottom">
          <h2>{I18n.t('key_DevicePartialSchedule_ScheduleTitle')}</h2>
        </div>

        <div className="card">
          <div className="card-body">
            <DeviceScheduleFormUpdate data={schedule}
                                      submit={this.handleSubmitUpdateSchedule}/>
          </div>
        </div>
      </div>
    )
  }
}

DevicePartialSchedule.propTypes = {
  deviceID: PropTypes.string.isRequired,
};

const mapStateToProps = (state, props) => {
  return {
    schedule: getDeviceScheduleByDeviceID(state, props.deviceID),
  }
};

export default withRouter(connect(mapStateToProps)(DevicePartialSchedule));
