import {requestJSON} from "../utils/request";
import {serialize} from "../utils/helpers";
import {LocalStorage} from "../utils/storage";

const api_host = window._env_.API_HOST;

class Device {

  static FileList(opts) {
    let uri = [api_host, "media", "file"].join("/");
    opts = opts || {};
    let qs = serialize(opts);
    uri += !!qs.length ? "?" + qs : "";
    return requestJSON("GET", uri, null, true);
  }

  static MediaPlanListByFile(file) {
    let uri = [api_host, "media", "file", file, 'mediaplans'].join("/");
    return requestJSON("GET", uri, null, true);
  }

  static FileRemove(file) {
    let uri = [api_host, "media", "file", file].join("/");
    return requestJSON("DELETE", uri, null, true);
  }

  static FileUploadURI() {
    return [api_host, "media", "upload"].join("/") + "?x-access-token=" + LocalStorage().get("token");
  }

}

export default Device