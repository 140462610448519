import React, {Component} from 'react';
import {connect} from "react-redux";
import {MediaPlan} from "../actions";
import {getMediaPlans} from "../selectors";
import {Preloader} from "../components";
import {NavLink} from "react-router-dom";
import {I18n} from "../utils/i18n";
import {MediaPlanCardList, Pagination} from "../containers";
import qs from "query-string";
import {serialize} from "../utils/helpers";

class MediaPlanPageList extends Component {
  constructor(props) {
    super(props);

    let query = qs.parse(this.props.history.location.search);

    this.state = {
      total: 0,
      page: parseInt(query.page, 10) || 1,
      limit: parseInt(query.limit, 10) || 50,
      pages: 1,
      maxPages: 5,
      pending: true,
    };
  }

  componentDidMount() {
    this.props.dispatch(MediaPlan.List({
      page: this.state.page,
      limit: this.state.limit
    }))
      .then((res) => {
        const pages = (res.total > this.state.limit) ? Math.ceil(res.total / this.state.limit) : 1;

        if (res.page > pages) return this.handlePageChange(pages);

        this.setState({total: res.total, page: res.page, pages: pages, pending: false})
      })
      .catch(() => this.setState({pending: false}));
  }

  handlePageChange = (page) => {
    let query = qs.parse(this.props.history.location.search);
    query.page = page;
    this.props.history.push({...this.props.location, search: "?" + serialize(query || {})});

    this.props.dispatch(MediaPlan.List({
      page: page,
      limit: this.state.limit
    }))
      .then((res) => {
        const pages = (res.total > this.state.limit) ? Math.ceil(res.total / this.state.limit) : 1;

        if (res.page > pages) return this.handlePageChange(pages);

        this.props.history.push({...this.props.location, search: "?" + serialize(query || {})});
        this.setState({total: res.total, page: res.page, pages: pages, pending: false})
      })
      .catch(() => this.setState({pending: false}));
  };

  render() {

    const {mediaPlans} = this.props;

    if (this.state.pending) return (
      <section className="container d-flex justify-content-center align-items-center vh-100">
        <Preloader/>
      </section>
    );

    return (
      <section className="container content">

        <div className="subnav-panel">
          <div className="d-flex">
            <div>
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><NavLink to={"/"}>{I18n.t('key_Home')}</NavLink></li>
                <li className="breadcrumb-item active">{I18n.t('key_MediaPlans')}</li>
              </ol>
            </div>
            <div className="ml-auto ml-4">
              <NavLink to="/mediaplans/new" className="btn btn-primary">
                {I18n.t('key_MediaPlanPageList_AddNewMediaPlan')}
              </NavLink>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <MediaPlanCardList mediaplans={mediaPlans}/>

            <div className="mt-4">
              <Pagination maxPages={this.state.maxPages}
                          currentPage={this.state.page}
                          numPages={this.state.pages}
                          change={this.handlePageChange}/>
            </div>
          </div>
        </div>

      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  mediaPlans: getMediaPlans(state),
});

export default connect(mapStateToProps)(MediaPlanPageList);